import _defineProperty from "@babel/runtime/helpers/esm/defineProperty";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import "core-js/modules/es.symbol.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.from-entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { useStore } from '@/store/useStore';
import WklrIconBtn from '@/components/shared/wklr-icon-btn.vue';
import TitleFilterItemGroup from './input-form-title-filter-item-group.vue';
import { WandhAvailableBooks } from './books';
export default defineComponent({
  name: 'LabsWandhInputFormTitleFilter',
  components: {
    TitleFilterItemGroup: TitleFilterItemGroup,
    WklrIconBtn: WklrIconBtn
  },
  setup: function setup() {
    var store = useStore();
    var availableBooks =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- input-formからしか呼んでいない
    inject('availableBooks');
    var selection = Object.fromEntries(Object.keys(availableBooks.value).map(function (group) {
      var _store$state$labs$wan;
      var allIDArray = computed(function () {
        return availableBooks.value[group].map(function (_ref) {
          var naturalID = _ref.naturalID;
          return naturalID;
        });
      });
      var allIDSet = computed(function () {
        return new Set(allIDArray.value);
      });

      /**
       * 書籍指定検索におけるIDリスト
       * （v-listで簡単に扱うためにここでnullではなくIDを埋めてしまう）
       */
      var ids = ref((_store$state$labs$wan = store.state.labs.wandh.inputQuery.filter.id[group]) !== null && _store$state$labs$wan !== void 0 ? _store$state$labs$wan : allIDArray.value);
      return [group, {
        all: computed({
          /** 全選択中か全解除中か中間か */get: function get() {
            var selected = ids.value.filter(function (id) {
              return allIDSet.value.has(id);
            }).length;
            var total = allIDSet.value.size;
            return selected === total ? true : selected > 0 ? 'indeterminate' : false;
          },
          /** 全選択・全解除 */set: function set(checked) {
            ids.value = checked ? _toConsumableArray(allIDArray.value) : [];
          }
        }),
        reloadFromStore: function reloadFromStore() {
          var _store$state$labs$wan2;
          ids.value = (_store$state$labs$wan2 = store.state.labs.wandh.inputQuery.filter.id[group]) !== null && _store$state$labs$wan2 !== void 0 ? _store$state$labs$wan2 : allIDArray.value;
        },
        ids: ids
      }];
    }));
    provide('selection', selection);
    var filterTextInput = ref('');
    provide('filterTextInput', filterTextInput);
    function onOpenClose(open) {
      if (open) {
        // Storeから読み直す
        selection.store.reloadFromStore();
        selection.other.reloadFromStore();

        // 絞り込みをリセット
        filterTextInput.value = '';

        // 選択済みのものは優先して表示
        var selectedStore = new Set(selection.store.ids.value);
        var selectedOther = new Set(selection.other.ids.value);
        availableBooks.value = {
          store: _toConsumableArray(availableBooks.value.store).sort(function (a, b) {
            return Number(selectedStore.has(b.naturalID)) - Number(selectedStore.has(a.naturalID));
          }),
          other: _toConsumableArray(WandhAvailableBooks).sort(function (a, b) {
            return Number(selectedOther.has(b.naturalID)) - Number(selectedOther.has(a.naturalID));
          })
        };
      } else {
        // Storeに保存
        store.commit('setLabsWandhInputQuery', _objectSpread(_objectSpread({}, store.state.labs.wandh.inputQuery), {}, {
          filter: _objectSpread(_objectSpread({}, store.state.labs.wandh.inputQuery.filter), {}, {
            id: {
              // 全選択時にはnullにしなければならない
              store: selection.store.all.value === true ? null : selection.store.ids.value,
              other: selection.other.all.value === true ? null : selection.other.ids.value
            }
          })
        }));
      }
    }
    return {
      selection: selection,
      availableBooks: availableBooks,
      filterTextInput: filterTextInput,
      onOpenClose: onOpenClose
    };
  }
});
export const WandhAvailableBooks = Object.freeze(
  Object.entries({
    kommentar_shojihomu_kaishaho_1: '会社法コンメンタール１ ― 総則・設立⑴',
    kommentar_shojihomu_kaishaho_10: '会社法コンメンタール10 ― 計算等⑴',
    kommentar_shojihomu_kaishaho_11: '会社法コンメンタール11 ― 計算等⑵',
    kommentar_shojihomu_kaishaho_12: '会社法コンメンタール12',
    kommentar_shojihomu_kaishaho_14: '会社法コンメンタール14 ― 持分会社⑴',
    kommentar_shojihomu_kaishaho_15: '会社法コンメンタール15 ― 持分会社⑵',
    kommentar_shojihomu_kaishaho_18: '会社法コンメンタール18',
    kommentar_shojihomu_kaishaho_20: '会社法コンメンタール20 ― 雑則⑵',
    kommentar_shojihomu_kaishaho_21: '会社法コンメンタール21 ― 雑則⑶・罰則',
    kommentar_shojihomu_kaishaho_3: '会社法コンメンタール３ ― 株式⑴',
    kommentar_shojihomu_kaishaho_4: '会社法コンメンタール４ ― 株式⑵',
    kommentar_shojihomu_kaishaho_5: '会社法コンメンタール５ ― 株式⑶',
    kommentar_shojihomu_kaishaho_7: '会社法コンメンタール７ ― 機関⑴',
    kommentar_shojihomu_kaishaho_8: '会社法コンメンタール８ ― 機関⑵',
    '9784785728687': '景品表示法〔第6版〕',
    '9784785728878': '株式が相続された場合の法律関係',
    '9784785728892': 'Law Practice 刑法〔第4版〕',
    '9784785728885': '役員処分事例集',
    '9784785728854': '商業登記ハンドブック〔第4版〕',
    '9784785728830': '取締役・執行役ハンドブック〔第3版〕',
    '9784785728694': '登記法入門――実務の道しるべ',
    '9784785728670': '敵対的株主提案とプロキシーファイト〔第3版〕',
    '9784785728663': '労働法で企業に革新を',
    kommentar_shojihomu_kaishaho_19: '会社法コンメンタール19 ― 外国会社・雑則⑴',
    '9784785728557': '会社補償 Q＆Aとモデル契約',
    '9784785728540': 'リーガルオペレーション革命──リーガルテック導入ガイドライン',
    '9784785728380': '若手弁護士・法務担当者のための会計入門',
    '9784785728038': '暗号資産・デジタル証券法',
    'jpx.017094175': '会社情報適時開示ガイドブック 2020年11月(2021年2月改訂反映版)',
    '9784785728335': '内部通報制度の理論と実務',
    '9784785728359': '地方自治体のリスク管理・危機管理 内部統制、コンプライアンスから防災・BCPまで',
    '9784785728274': '消費者裁判手続特例法 〔第2版〕',
    '9784785728229': '独占禁止法 〔第4版〕',
    '9784785728182': '詐害行為取消権の行使方法とその効果',
    '9784785728212': '最新・改正独禁法と実務 令和元年改正・平成28年改正',
    '9784641137318': '契約法',
    '9784785728199': '新・日本の会社法〔第2版〕',
    '9784785728175': '債権回収基本のき 第5版',
    '9784785728083': '継続的取引における担保の利用法',
    '9784785727987': '会社法〔第2版〕',
    '9784785727956': '弁護士になった「その先」のこと。',
    '9784785727901': '第三者委員会報告書30選',
    '9784788287556': '障害者をめぐる法律相談ハンドブック',
    '9784785727529': 'メーカー取引の法律実務 Q&A',
    '9784788287464': '〔改訂版〕Q&A 成年被後見人死亡後の実務と書式',
    '9784788287433': '実務家が陥りやすい 相続人不存在・不在者 財産管理の落とし穴',
    '9784130323895': '会社法［第2版］',
    '9784785727789': 'Law Practice 商法 〔第4版〕',
    kommentar_shojihomu_kaishaho_16: '会社法コンメンタール16 ― 社債',
    kommentar_shojihomu_kaishaho_17: '会社法コンメンタール17',
    kommentar_shojihomu_kaishaho_6: '会社法コンメンタール６ ― 新株予約権',
    '9784788286818': '交通事故事件の実務―裁判官の視点―',
    '9784641137769': '担保物権法 現代民法３ 第4版',
    '9784785727628': 'Q&A 兼務役員の法務と実務―企業集団における人材活用',
    '9784130323925': '刑法各論講義［第7版］',
    '9784785727666': '約款の基本と実践',
    '9784326403721': '勁草法律実務シリーズ 実務成年後見法',
    '9784785727581': 'コンメンタール消費者契約法〔第2版増補版〕補巻――2016年・2018年改正',
    '9784785727567': '新版 商品先物取引法',
    '9784785727512': '事業再生大全',
    '9784785727574': '対談で読み解くサイバーセキュリティと法律',
    '9784788286436':
      '〔補訂版〕成年後見手続ガイドブック ―介護・福祉・医療サービスの活用、日常生活支援、裁判所への申立て等―',
    '9784785727406': '起業の法務―新規ビジネス設計のケースメソッド',
    '9784788286320': '働き方改革関連法対応 Q&A 改正労働時間法制のポイント',
    '9784788286337': 'ケース別 債権法 新・旧規定適用判断のポイント',
    '9784785727482': '契約類型別 債権法改正に伴う契約書レビューの実務',
    '9784502287619': '買収ファイナンスの法務(第2版)',
    '9784785727475': '会社法 実務問答集Ⅲ',
    kommentar_shojihomu_kaishaho_sup: '会社法コンメンタール補巻 ― 平成26年改正',
    '9784785727468': 'データ取引の契約実務――書式と解説',
    '9784785727413': '合併ハンドブック 第4版',
    '9784788286146': '建設業法の課題と実務対応 電子契約化への法的アプローチ',
    '9784785727321': '売買における買主の追完請求権の基礎づけと内容確定',
    '9784785727352': '不動産再開発の法務〔第2版〕―都市再開発・マンション建替え・工場跡地開発の紛争予防',
    // '9784785727345': 'ガイダンス 監査役・監査役会の実務',
    '9784502238611': '業務委託契約の基本と書式',
    '9784788285880': '相続税 更正の請求―Q&Aと事例解説―',
    '9784785727253': '重要論点 実務 民法（債権関係）改正',
    '9784785727260': '日弁連ESGガイダンスの解説とSDGs時代の実務対応',
    '9784788285828': 'ミス事例でわかる 法人税の実務ポイント',
    // '9784785727246': '役員のための法律知識〔第2版〕',
    '9784785727178': 'コンプライアンス・内部統制ハンドブックII',
    '9784641137226': '不法行為法〔第 2 版〕',
    '9784785725341': '個人情報保護法〔第3版〕',
    '9784785727079': '一問一答・新しい相続法 : 平成30年民法等(相続法)改正、遺言書保管法の解説',
    '9784785726782': '一問一答・平成30年商法改正',
    '9784785752750': '別冊商事法務№441 事業報告記載事項の分析――2018年6月総会会社の事例分析――',
    '9784785727055': 'シンジケート・ローンの法的課題',
    '9784785727048': '企業労働法実務相談',
    '9784788285194': '事例でみる スタンダード債権回収手続 ―専門家の視点と実務対応―',
    '9784641227231': '憲法(第3版)',
    '9784785726980': '一問一答・平成30年人事訴訟法・家事事件手続法等改正 : 国際裁判管轄法制の整備',
    '9784785726928': '実践！ 債権保全・回収の実務対応 ──担保の取得と実行のポイント〔第2版〕',
    '9784785726973': '合同会社の法と実務',
    '9784788285026': '給与所得者以外の逸失利益算定事例集―事業所得者・自由業・会社役員等―',
    '9784785726997': '指名諮問委員会・報酬諮問委員会の実務〔第2版〕',
    '9784785726836': '成長戦略と企業法制 成長戦略法制 : イノベーションを促進する企業法制設計',
    '9784788285019': '不動産 権利者の調査・特定をめぐる実務',
    '9784785726867': 'M&A法大全（上）〔全訂版〕',
    '9784785726874': 'M&A法大全（下）〔全訂版〕',
    '9784785726966': 'データの法律と契約',
    '9784785726935': '信託法をひもとく',
    '9784502147517': '新・会社法実務問題シリーズ/2 株式・種類株式〈第2版〉',
    '9784785726911': '一問一答 成年年齢引下げ',
    '9784788284975': '契約違反と信頼関係の破壊による 建物賃貸借契約の解除 ―違反類型別 賃貸人の判断のポイント―',
    '9784788284968':
      '問題解決のための民事信託活用法―不動産有効活用、相続対策、後継者育成・事業承継対策、空き家対策等の視点から―',
    '9784788284951': '介護・医療現場が知っておくべき 認知症高齢者への対応と法律問題',
    '9784788284876': '株式会社との対比でみる合同会社の法務・登記・税務',
    '9784641138001': 'M&A契約研究 理論・実証研究とモデル契約条項',
    '9784785726829': 'コーポレートガバナンス・コードの実務〔第3版〕',
    '9784788283312': '事例でみる事業承継の実務 : 士業間連携と対応のポイント',
    '9784788284869': '非上場株式評価のチェックリストと着眼点',
    '9784785726775': '定型約款の実務Q&A',
    '9784788284821': 'キーワードからひもとく 権利登記のポイント 元登記官の視点',
    '9784788284760': '非公開株式評価実務マニュアル',
    '9784788284784': '農地登記申請MEMO',
    '9784785726669': '契約法の現代化II―民法の現代化',
    '9784641137653': '信託法現代民法 別巻',
    '9784788284692': '訴訟類型別 訴状審査をめぐる実務',
    '9784785726713': '企業不祥事のケーススタディ――実例と裁判例',
    '9784788284661': '不貞慰謝料の算定事例集 判例分析に基づく客観的な相場観',
    '9784785726638': '事業譲渡の実務 法務・労務・会計・税務のすべて',
    '9784641144866': '労働契約法 第2版',
    '9784785726584': '債権法改正と民法学Ⅱ 債権総論・契約 ⑴',
    '9784785726591': '債権法改正と民法学Ⅲ 契約 ⑵',
    '9784788284678': '〔平成30年度税制改正対応版〕 事業承継相談対応マニュアル',
    '9784641144934': '独占禁止法〔第3版〕',
    '9784788284609': '借地上の建物をめぐる実務と事例 朽廃・滅失、変更、譲渡',
    '9784785726621': '法務担当者のための もう一度学ぶ民法（契約編）〔第2版〕',
    '9784788284524': '死因贈与の法律と実務',
    '9784641136519': '民事執行・保全入門〔補訂版〕',
    '9784788284487': '農業・農地をめぐる税務上の特例―ケース別適用のポイントと計算例―',
    '9784785726492': '社債ハンドブック',
    '9784502154515': '新・会社法実務問題シリーズ/6 監査役・監査委員会・監査等委員会',
    '9784785726485': '実効的子会社管理のすべて',
    '9784785726393': '一問一答・平成28年刑事訴訟法等改正',
    '9784788284326': 'Q&A 介護職種の技能実習生受入れの手引',
    '9784641137912': '保険法(上)',
    '9784785726386': '執行役員の実務',
    '9784788284289': 'Ｑ＆Ａ 空き家をめぐる税務－空き家譲渡の3,000万円控除の特例を中心に－',
    '9784785726256': '民法改正対応 取引基本契約書作成・見直しハンドブック',
    '9784785726133': '第二種金融商品取引業の手引き',
    '9784641135253': '民法講義Ⅰ総則 〔第3版〕',
    '9784788283770': 'Q&A 未分割遺産の管理・処分をめぐる実務',
    '9784788283718': 'Q&A抵当権の法律と登記',
    '9784785752644': '別冊商事法務№430 事業報告記載事項の分析――平成29年6月総会会社の事例分析',
    '9784785726102': 'AIの法律と論点',
    '9784785726034': 'シチュエーション別 提携契約の実務〔第３版〕',
    '9784785726157': '会社法 実務問答集Ⅱ',
    '9784785726041': '支払決済法〔第３版〕 ──手形小切手から電子マネーまで──',
    '9784785726171': '適時開示の実務Q&A〔第2版〕',
    '9784785726065': 'M&Aリスク管理の最前線 : 国内外の最新実務',
    '9784785726058': '会社法決算書作成ハンドブック〈2018年版〉',
    '9784785725976': '実務解説 特定商取引法〔第2版〕',
    '9784130323888': '刑事訴訟法講義［第6版］',
    '9784785726003': 'M&A契約――モデル条項と解説',
    '9784785725938': '成長戦略と企業法制 会社補償の実務',
    '9784785725907': '役員のための株主総会運営法〔第３版〕',
    '9784785725853': '企業不動産法〔第2版〕',
    '9784641046740': '新・国際売買契約ハンドブック',
    '9784641139152': '刑法総論 [第3版]',
    '9784785725884': '損失補てん規制',
    '9784785725822': '裁判実務シリーズ4 民事再生の手引〔第２版〕',
    '9784785725815': '講義債権法改正',
    '9784788282339': 'ケース別 農地の権利移動・転用可否判断の手引',
    '9784785725808': 'ケースから考える内部統制システムの構築',
    '9784785725785': '権利保護保険のすべて',
    '9784641227170': '新・情報公開法の逐条解説〔第7版〕',
    '9784785725730': '個人情報保護法制と実務対応',
    '9784785725747': '資金調達ハンドブック〔第2版〕',
    '9784785725709': '実務家のための役員報酬の手引き〔第2版〕',
    '9784788283534': '女性活躍推進法・改正育児介護休業法対応 女性社員の労務相談ハンドブック',
    '9784785725693': 'はじめて学ぶ下請法',
    '9784785725679': '逐条解説 マイナンバー法',
    '9784788283527': 'Q&A有期契約労働者の無期転換ルール',
    '9784788283244': '判例データブック 借地借家の正当事由・立退料',
    '9784502209116': '企業訴訟実務問題シリーズ 労働訴訟―解雇・残業代請求',
    '9784788283305': 'Q&A相続人不存在・不在者財産管理の手引',
    '9784785725563': '民法から考える民事執行法・民事保全法〔第2版〕',
    '9784785725495': '契約業務の実用知識〔第2版〕',
    '9784785725532': '取締役の義務と責任',
    '9784785725471': '種類株式ハンドブック',
    '9784785725464': '会社法書式集',
    '9784788283176': '家事事件における保全処分の実務と書式',
    // '9784785725440': '社外監査役の手引き',
    '9784785725372': '大量保有報告制度の理論と実務',
    '9784785752576': '別冊商事法務№423 機関投資家の議決権行使方針及び結果の分析',
    '9784641137011': '金融商品取引法概説 第2版',
    '9784785725327': '新しい役員責任の実務〔第3版〕',
    '9784788283039': '建物漏水をめぐる法律実務',
    '9784788282834': '〔改訂版〕 各種法人関係 議事録モデル文例集',
    '9784788283002': '抹消登記申請MEMO',
    '9784502229817': '企業訴訟実務問題シリーズ 消費者契約訴訟―約款関連',
    '9784785725310': '新しい債権法を読みとく',
    '9784785725204': '会社訴訟ハンドブック',
    '9784502072109': 'トップ・ミドルのための 採用から退職までの法律知識〔十四訂〕',
    '9784785725198': 'コーポレートガバナンスハンドブック',
    '9784788282926': '判例・裁決例にみる 関連会社・役員との取引をめぐる税務判断',
    '9784785725273': '成長戦略と企業法制 D&O保険の先端Ⅰ',
    '9784788282902': '改正消費者契約法対応Q&A 消費者取引トラブル解決の手引',
    '9784785725181': 'コンプライアンス・内部統制ハンドブック',
    '9784785725242': '金融商品取引法アウトライン',
    '9784788282810': '困難事例にみる 用地取得・損失補償の実務',
    '9784641137509': '会社法大要[第2版]',
    '9784785752552': '別冊商事法務№421 東証一部上場会社の役員報酬設計――報酬水準・報酬制度の分析――',
    '9784502223211': '企業訴訟実務問題シリーズ 環境訴訟',
    '9784502222917': '企業訴訟実務問題シリーズ 独禁法訴訟',
    '9784785725143': '企業統治と取締役会',
    '9784785725105': '事例にみる信用取引トラブル解決集',
    '9784785725051': 'オーラルヒストリー企業法務',
    '9784785725037': '会社法 実務問答集Ⅰ（下）',
    '9784785725020': '会社法 実務問答集Ⅰ（上）',
    '9784641227217': '行政不服審査法の逐条解説 第2版',
    '9784502209017': '企業訴訟実務問題シリーズ 企業訴訟総論',
    '9784502204616': '企業訴訟実務問題シリーズ 証券訴訟―虚偽記載',
    '9784788282254': '〔改訂版〕ケース別 相続手続 添付書類チェックリスト',
    // '9784785724948': 'IT ビジネスの契約実務',
    '9784788282117': '説明義務の理論と実際',
    '9784785724924': 'コンメンタール 会社計算規則・商法施行規則〔第3版〕',
    '9784788282124': '不動産明渡・引渡事件処理マニュアル',
    '9784785724856': '放送制度概論――新・放送法を読みとく',
    '9784785724825': '裁判実務シリーズ10 民事執行実務の論点',
    '9784785724702': '契約規範の法学的構造',
    '9784785724580': '会社法実務相談',
    '9784788281950': '業種別 税務調査のポイント ―国税調査官の視点とアドバイス―',
    '9784788281868': '国際税務の専門家からみた出国税と国外財産調書等の実務',
    '9784785724627': '公開買付けの理論と実務〔第3版〕',
    '9784785724696': '契約法の現代化Ⅰ――契約規制の現代化',
    '9784785724658': 'タクティクスアドバンス 民法 2017',
    '9784641136939': '金融商品取引法',
    '9784785724559': 'ファイナンス法 : 金融法の基礎と先端金融取引のエッセンス',
    '9784785724597': '論点解析 経済法〔第2版〕',
    '9784785724573': '取締役会報告事項の実務〔第2版〕',
    '9784785724511': '商事法論集Ⅰ 会社法論集',
    '9784785724405': '裁判実務シリーズ9 交通関係訴訟の実務',
    '9784785724412': 'コンパクト解説会社法6 定款作成・変更の実務',
    '9784785724368': 'FinTechビジネスと法25講 : 黎明期の今とこれから',
    '9784785724399': 'ユーザを成功に導くシステム開発契約〔第2版〕 ──クラウドを見据えて',
    '9784785724375': '発信者情報開示・削除請求の実務 : インターネット上の権利侵害への対応',
    '9784785724344': 'わたしたちの社会と法―学ぼう・法教育',
    '9784785724290': '要件事実問題集〔第4版〕',
    '9784785724320': 'よくわかるインサイダー取引規制入門Q&A',
    '9784785752439': '別冊商事法務 No.409 後発事象ベストプラクティス',
    '9784785752422': '別冊商事法務No.408 2015年版 新規上場の戦略――企業統治・資金調達・役職員待遇・子会社上場',
    '9784785724207': '債権管理・保全・回収の手引き',
    '9784785724108': 'コンパクト解説会社法2 取締役・取締役会・執行役',
    '9784785724122': 'コンパクト解説会社法４ 会社法の議事録作成実務 ―株主総会・取締役会・監査役会・各委員会',
    '9784785724214': '取締役会実効性評価の実務',
    '9784785724115': 'コンパクト解説会社法３ 監査役・監査委員・監査等委員',
    '9784785724139': 'コンパクト解説会社法５ 組織再編',
    '9784641042766': '刑法各論 第2版',
    '9784785724047': '新しい事業報告・計算書類〔全訂版〕',
    '9784785752408': '別冊商事法務No.406 事業報告記載事項の分析――平成28年1月総会までを踏まえて',
    '9784785723965': '詳解 大量保有報告制度',
    '9784785723910': '投資信託・投資法人の法務',
    '9784785723866': '本林塾講演録 新時代を切り拓く弁護士',
    '9784785723989': '危機管理法大全',
    '9784785723842': 'コンパクト解説会社法１ 株主総会',
    '9784785723835': 'コーポレートガバナンス・コード対応 ベストプラクティス株主総会',
    '9784785723699': '企業法務のための労働組合法25構',
    '9784785723620': '投資信託の法制と実務対応',
    '9784785723569': '特許権行使の制限法理',
    '9784785723651': '会社解散・清算手続と法人税申告実務〔第2版〕',
    '9784785723415': '会社経営者・人事労務担当者のための労働法実務ハンドブック',
    '9784785723521': '消費者取引の法務',
    '9784785723514': '時代を彩る商事判例',
    '9784785723330': 'よくわかる独禁法グローバル実務',
    '9784785723439': 'コンテンツ・セキュリティと法',
    '9784785723361': 'ターンアラウンド・マネージャーの実務',
    '9784785723385': '平成27年改正個人情報保護法のしくみ',
    '9784785721176': '逐条解説・家事事件手続法',
    '9784785723323': '公開買付規制の基礎理論',
    '9784785723316': '法律実務家が知っておきたい作法',
    '9784785723392': '訴訟の技能 : 会社訴訟・知財訴訟の現場から',
    '9784641137165': '非典型担保法の課題現代民法研究II',
    '9784785723293': 'JLF叢書 交渉教育の未来 ─良い話し合いを創る 子供が変わる',
    '9784785723248': '一問一答・国際的な子の連れ去りへの制度的対応 : ハーグ条約及び関連法規の解説',
    // '9784785723279': '社債法',
    '9784785723286': '監査等委員会設置会社の活用戦略',
    '9784641017498': '新版 注釈民法（9）物権（4） 改訂版',
    '9784785723224': '非公開会社のためのやさしい会社法',
    '9784785723255': '法教育への招待 : 法学から見た法教育',
    '9784785723200': 'コーポレートガバナンス・コードを読み解く',
    '9784641215078': 'ジュリスト増刊 実務に効く 国際ビジネス判例精選',
    '9784785723118': '不祥事対応ベストプラクティス : 実例から読み解く最新実務',
    '9784785723149': '金融商品取引法――資本市場と開示編〔第３版〕',
    '9784130323758': '国際経済法',
    '9784641017047': '新版 注釈民法（4） 総則（4）',
    '9784785722975': '監査役・監査委員ハンドブック',
    '9784785722982': '実務家のための 取締役の競業取引・利益相反取引規制〔第2版〕',
    '9784785722944': '新マンション建替え法 逐条解説・実務事例',
    '9784785723040': '論点詳解 平成26年改正会社法',
    '9784785722616': '逐条解説・非訟事件手続法',
    '9784785722883': '監査等委員会設置会社のフレームワークと運営実務 : 導入検討から制度設計・移行・実施まで',
    '9784785722784': 'Q&A 監査等委員会設置会社・社外取締役の実務',
    '9784785722807':
      '森・濱田松本法律事務所 シリーズ改正会社法 Q&A株式・組織再編の実務1 ――キャッシュ・アウト制度を中心に',
    '9784785722814': '森・濱田松本法律事務所 シリーズ改正会社法 Q&A株式・組織再編の実務2 ──株式買取請求制度を中心に',
    '9784785752293': '別冊商事法務No.395 財務情報の開示と傾向',
    '9784785722685': '非公開会社・子会社のための会社法実務ハンドブック',
    '9784785722722': '平成26年 会社法改正と実務対応[改訂版]',
    '9784785722739': '取締役会の法と実務',
    '9784785722661': '監査等委員会導入の実務',
    '9784785752286': '別冊商事法務No.394 事業報告記載事項の分析――平成26年6月総会会社の事例分析',
    '9784785722630': '金融商品取引法入門〔第4版〕',
    '9784785722562': '実践平成26年会社法改正後のIR総会とガバナンス',
    '9784785722548': '少額債権の管理・保全・回収の実務',
    '9784785722425': '多重代表訴訟制度のあり方 : 必要性と制度設計',
    '9784785722418': '臨時報告書作成の実務Q&A',
    '9784785752248':
      '別冊商事法務 No.390 会計不正が株主総会に与える影響の事例分析ー関係書類の特殊記載と直前発覚時の運営方法ー',
    '9784785722364': '実務解説平成26年会社法改正',
    '9784785722319': '外国公務員贈賄規制と実務対応',
    '9784641144699': '著作権法 第2版',
    kommentar_shojihomu_kaishaho_9: '会社法コンメンタール９ ― 機関⑶',
    '9784785722081': '一問一答・被災借地借家法・改正被災マンション法',
    kommentar_shojihomu_kaishaho_13: '会社法コンメンタール13 ― 清算⑵',
    '9784785722012': '取引先リスク管理Q&A',
    '9784785752217': '別冊商事法務No.387 東京大学比較法政シンポジウム 日本再興のためのコーポレートガバナンス改革',
    '9784785721879': '有価証券報告書作成の実務Q&A',
    kommentar_shojihomu_kaishaho_2: '会社法コンメンタール２ ― 設立⑵',
    '9784785721558': '適合性原則と私法理論の交錯',
    '9784785721596': '債権回収早わかり',
    '9784785721428': '独禁法による独占行為規制の理論と実務 : わが国の実務のどこに問題があるか',
    '9784785721466': '組織再編セミナー : 法務・会計・税務のポイント',
    '9784641017504': '新版 注釈民法（27）相続（2） 補訂版',
    '9784785721244': '民法（債権関係）部会資料集 第２集〈第6巻〉 ――第46回～第49回会議 議事録と部会資料――',
    '9784785721367': '実務解説消費税転嫁特別措置法',
    '9784785721152': '濫用的会社分割──その態様と実務上の対応策',
    '9784788276765': '民事保全申立ハンドブック──「種類」と「必要性」の検討──',
    '9784785721084': '株式買取請求権の構造と買取価格算定の考慮要素',
    '9784785721053': '金商法・行為規制の手引き',
    '9784785721091': '法律意見書の読み方 : 15のストーリーで学ぶ',
    '9784785720995': 'よくわかる共通番号法入門 : 社会保障・税番号のしくみ',
    '9784785720896': '倒産と訴訟',
    '9784785720919': '日本改造計画 : ガバナンスの視点から',
    '9784785720865': '解説特定非営利活動法人制度',
    '9784785720629': '労契法・派遣法・高年法平成24年改正Q&A',
    '9784785720612': '債権譲渡禁止特約の研究',
    '9784785720681': '原発賠償中間指針の考え方',
    '9784785720674': '会社法改正要綱の論点と実務対応',
    '9784785720605': '債権法改正と裁判実務Ⅱ ――要件事実・事実認定の重要論点',
    '9784785720568': '2013年版 株主総会想定質問と回答 : 株主質問にどう答えるか',
    '9784785720438': '株主と対話する企業 : 株主価値の持続的成長を実現させるIR・SR',
    '9784785720476': '友好的買収の場面における取締役に対する規律',
    '9784785720384': '民法（債権関係）部会資料集 第２集〈第２巻〉 ――第30回～第34回会議 議事録と部会資料――',
    '9784785720377': '外国会社と登記 【全訂版】',
    '9784785720360': '商業・法人登記先例インデックス',
    '9784785720315': '経済刑法',
    '9784785720285': 'ハンドブック 独立役員の実務',
    '9784785720117': '医療と法の交錯 : 医療倫理・医療紛争の解決',
    '9784785720155': '金商法という地図の読み方',
    '9784785720186': '第2版 わかりやすい労働契約法',
    '9784785720100': '金商法大系Ⅰ 公開買付け (2)',
    '9784785720087': '具体例で考える成年後見制度',
    '9784785719906': '破産法・民事再生法概論',
    '9784785719951': '特許法における明細書による開示の役割 : 特許権の権利保護範囲決定の仕組みについての考察',
    '9784785719876': '金融商品取引法コンメンタール 第3巻 自主規制機関',
    '9784641017078': '新版 注釈民法（7） 物権（2）',
    '9784785719807': '「はしがき」に見る企業法務の軌跡',
    '9784785719623': '会社法制最新事情と株式実務Q&A',
    '9784785719616': '一問一答シリーズ 一問一答 平成23年民事訴訟法等改正 国際裁判管轄法制の整備',
    '9784785719425': 'Q&A 震災と相続の法律相談',
    '9784785719463': '一問一答シリーズ 一問一答 家事事件手続法',
    '9784788275058': '事例にみる 法人格なき団体',
    '9784785719456': '一問一答シリーズ 一問一答 非訟事件手続法',
    '9784785719487': '金融商品取引法における課徴金事例の分析 <I> インサイダー取引編',
    '9784785719494': '金融商品取引法における課徴金事例の分析 <II> 虚偽記載編',
    '9784785719418': '会社法の正義',
    '9784785719388': '情報セキュリティの法律 〔改訂版〕',
    '9784785719326': '一問一答シリーズ 一問一答 平成23年民法等改正 児童虐待防止に向けた親権制度の見直し',
    '9784788274518': '特別受益・寄与分の理論と運用──裁判例の分析を中心として──',
    '9784788273849': '〔改訂版〕Q&A 遺留分の実務',
    '9784785719296': '金融商品取引法コンメンタール4',
    '9784788271623': '〔改訂版〕借地借家の正当事由と立退料 判定事例集',
    '9784785719135': '判例に見る会社法の内部統制の水準',
    '9784788273801': 'Ｑ＆Ａ 休職・休業・職場復帰の実務と書式',
    '9784785719005': '企業結合規制 : 独占禁止法による競争評価の理論',
    '9784788274037': '事例にみる 消費者契約法における不当条項',
    '9784785718961': 'Q&A 震災と債権回収・倒産対応',
    '9784785718909': 'Q&A 震災と住まいの法律相談',
    '9784785718862': 'Q&A 東日本大震災と税務対応',
    '9784785718886': '民法(債権関係)の改正に関する中間的な論点整理の補足説明',
    '9784785718855': 'Q&A 震災と雇用問題',
    '9784785718787': 'Q&A 東日本大震災と事業継続の法務',
    '9784785718794': '大震災後の株主総会直前対策',
    '9784785718749': 'Q&A 東日本大震災と登記実務',
    '9784785718718': '大震災と株主総会の実務',
    '9784785718510': '税務訴訟入門 第5版',
    '9784785718442': 'ガイドブック 会社の計算〔M&A編〕',
    '9784785718220': 'Q&A 取締役会運営の実務',
    '9784788273498': '借家トラブル解決文例集',
    '9784785718077': '労働法問題集',
    '9784785717919': '技術標準をめぐる法システム――企業間協力と競争,独禁法と特許法の交錯',
    '9784785717902': '知財デューデリジェンス',
    '9784785717810': '「法と教育」序説',
    '9784785717797': 'ベンチャー企業の法務・財務戦略',
    '9784788272347': '抗告・異議申立ての実務と書式',
    '9784785713331': '論点解説 新・会社法 千問の道標',
    '9784785717575': '新訂 労働法',
    '9784785716868': '詳解・債権法改正の基本方針Ⅰ : 序論・総則',
    '9784785716875': '詳解・債権法改正の基本方針Ⅱ : 契約および債権一般(1)',
    '9784785717001': '債権法の新時代 : 「債権法改正の基本方針」の概要',
    '9784641017481': '新版 注釈民法（6）物権（1） 補訂版',
    '9784785713522': '一問一答 新しい国際私法 法の適用に関する通則法の解説',
    '4785713445': '新会社法第2版 新しい会社法は何を考えているのか',
    '478571221X': '改正民法の解説 [保証制度・現代語化]',
    '4785712333': '一問一答 平成16年改正 民事訴訟法 非訟事件手続法 民事執行法',
    '4785712058': 'Q&A 平成16年改正会社法 電子公告・株券不発行制度',
    '4785711116': '一問一答 新会社更生法',
    '4785710853': '一問一答 改正マンション法 平成14年区分所有法の解説',
    '4785710640': 'Q&A 平成14年改正商法',
    '4785710012': '国際倒産法制',
    '9784641046795': 'ケースで学ぶ 国際企業法務のエッセンス',
    '9784641137448': '破産法・民事再生法(第 4 版)',
    '9784641243019': '特許法〔第 2 版〕',
    '9784641243118': '商標法 第2版',

    // 2024-06-13 Wave 1 https://www.notion.so/legalscape/df23b91407ce4f81bbc57e47e6f3ca05#72d39b515fc34b079b4966ddf504ada5
    '4785712988': '一問一答 不動産登記法等一部改正法',
    '9784322141948': '民事執行入門',
    '9784322142174': '死刑を考える',
    '9784322142181': '金融規制の論点25',
    '9784322142198': '電子署名活用とＤＸ',
    '9784322142204': '地域金融の活用術',
    '9784322142464': '所有者不明土地法制',
    '9784322142471': '女性と定年',
    '9784322143423': 'Ｑ＆Ａ 人権ＤＤ',
    '9784322143607': 'KINZAIバリュー叢書L わかる経済安全保障',
    '9784322143638': 'KINZAIバリュー叢書L 信託登記の照会事例 1',
    '9784322143652': 'KINZAIバリュー叢書L わかるファンド契約',
    '9784326504220': 'ネット炎上の研究 誰があおり、どう対処するのか',
    '9784502147814': '再生コンサルティングの質を高める 事業デューデリジェンスの実務入門',
    '9784641137356': '解説 民法（債権法）改正のポイント',
    '9784641137622': '会社訴訟・紛争実務の基礎──ケースで学ぶ実務対応',
    '9784641137899': '倒産処理法入門 第5版',
    '9784641243026': '独禁法事例集',
    '9784785721954': '新・更生計画の実務と理論',
    '9784785723712': 'コーポレートガバナンス・コード対応招集通知等見直しの実務',
    '9784785726706': 'よくわかる投資協定と仲裁',
    '9784785727062': '株主総会・取締役会・監査役会の 議事録作成ガイドブック〔第2版補訂版〕',
    '9784785728113': '企業法務におけるナレッジ・マネジメント',
    '9784785752446': '別冊商事法務 No.410 株主還元の実態調査──配当,自己株式の取得・処理,株主優待──',
    '9784785752514':
      '別冊商事法務 No.417 平成27・28年の政策保有株式の比較──コーポレートガバナンス・コードが及ぼした影響──',
    '9784785752590': '別冊商事法務№425 自己資本利益率（ROE）の分析――国際的・長期的・業種別の分析',
    '9784785752705': '別冊商事法務№436 取締役会評価の現状（平成30年版）――国内外の開示事例の分析――',
    '9784326402847': '電子商取引法',
    '9784326403240': '再生可能エネルギー法務',
    '9784326403349': '情報法のリーガル・マインド',
    '9784326403509': '損害賠償の法務 勁草法律実務シリーズ',
    '9784326403608': '契約の法務 第2版 勁草法律実務シリーズ',
    '9784326403615': '資金決済法の理論と実務 勁草法律実務シリーズ',
    '9784326403646': '勁草法律実務シリーズ 民事証拠収集 相談から執行まで',
    '9784326403752': 'スタートアップの知財戦略 事業成長のための知財の活用と戦略法務',
    '9784326403820': '改正民事執行法の論点と今後の課題',
    '9784326403837': '持続可能な地域活性化と里山里海の保全活用の法律実務 SDGs、地方創生ビジネス、再生可能エネルギー',
    '9784326403868': '企業法務入門20講',
    '9784326403929': '〈ケース研究〉著作物の類似性判断 ビジュアルアート編',
    '9784326404056': 'デザイン保護法',
    '9784326404186': '一般条項の理論・実務・判例 第1巻 基礎編',
    '9784326404193': '一般条項の理論・実務・判例 第2巻 応用編',
    '9784326404209': '災害法律相談Q&A',
    '9784326449644': '家事法の理論・実務・判例1',
    '9784326449668': '家事法の理論・実務・判例3',
    '9784326449675': '家事法の理論・実務・判例4',
    '9784384046366': '事業者必携 いざというときに役立つ リスクマネジメントの法律知識と対策',
    '9784384046489': 'すぐに役立つ 投資家のための 図解 はじめての決算書・税金・法律入門',
    '9784384046519':
      '事業者必携 各種申請書記載例つき！資金調達から登記、税務、社保手続きまで 最新 起業のための設立＆運営手続きサポートマニュアル',
    '9784384046526': 'すぐに役立つ 入門図解 法人税のしくみと手続き',
    '9784384046533': 'すぐに役立つ 入門図解 マイナンバー法のしくみと手続き',
    '9784384046540': '事業者必携 株式会社の議事録と登記 作成法と記載例93',
    '9784384046557': '事業者必携 入門図解 最新はじめての消防法',
    '9784384046571': 'すぐに役立つ 仕訳と勘定科目の基本と実践トレーニング151',
    '9784384046618': '図解で早わかり 最新 不動産登記のしくみと手続き',
    '9784384046656': '図解で早わかり 債権回収の法律と実務',
    '9784384046694': 'すぐに役立つ 最新 マンションを「売るとき」「買うとき」の法律マニュアル',
    '9784384046700': 'すぐに役立つ 退職者のための医療保険・生活保護・年金・介護保険のしくみと手続き',
    '9784384046731': '事業者必携 最新 ネットビジネス・通販サイト運営のための法律知識',
    '9784384046793': 'すぐに役立つ 相続・贈与の法律・登記・税務 手続きと書式マニュアル',
    '9784384046816': 'すぐに役立つ 入門図解 改正対応！個人情報保護法とマイナンバー法のしくみ',
    '9784384046885': '事業者必携 フードビジネスのための 最新 飲食業の法律問題と実務マニュアル',
    '9784384046915': '事業者必携 入門図解 これだけは知っておきたい 介護施設の法律問題・施設管理マニュアル',
    '9784384047356': 'すぐに役立つ 困ったときに読む 入門図解 親の病気・入院・介護 手続きサポートマニュアル',
    '9784384047387': 'すぐに役立つ 入門図解 不動産登記の法律と申請手続きマニュアル',
    '9784384047394': '事業者必携 イザというときに困らない！税務調査・労基署調査・社会保険調査のしくみと対策',
    '9784384047400': 'すぐに役立つ これならわかる 入門図解 任意売却と債務整理のしくみと手続き',
    '9784384047417': 'すぐに役立つ 入門図解 特許・商標のしくみと手続き',
    '9784384047424': 'すぐに役立つ 入門図解 強制執行のしくみと手続き ケース別実践書式33',
    '9784384047431': 'すぐに役立つ 入門図解 最新よくわかる障害者総合支援法',
    '9784384047448': 'すぐに役立つ 入門図解 介護施設・高齢者向け住宅のしくみと疑問解決マニュアル',
    '9784384047455': 'すぐに役立つ 入門図解 障害年金・遺族年金のしくみと申請手続き ケース別32書式',
    '9784384047462': '図解 最新 不動産の法律と手続きがわかる事典',
    '9784384047486': '事業者必携 中小事業者のための 建設業許可申請と経営事項審査 手続きマニュアル',
    '9784384047493':
      'すぐに役立つ 売却、賃貸、民泊、税金対策まで 入門図解 実家の空き家をめぐる法律問題と対策 実践マニュアル',
    '9784384047530': 'すぐに役立つ 入門図解 記載例つき 遺言書の書き方と生前贈与 しくみと対策',
    '9784384047622': 'すぐに役立つ 入門図解 最新 アパート・マンション・民泊経営をめぐる法律と税務',
    '9784384047684': '図解 民法改正対応！ 最新 債権回収のしくみがわかる事典',
    '9784384047769': '事業者必携 抵当・保証の法律と担保をめぐるトラブル解決法',
    '9784384047776': '図解 最新 労働基準法と労働条件の基本がわかる事典',
    '9784384047783': '事業者必携 民法改正対応！入門図解 契約書・印鑑・印紙税・領収書の法律知識',
    // '9784384047844': 'すぐに役立つ 入門図解 最新 内容証明郵便・公正証書・支払督促の手続きと書式サンプル50',
    // '9784384047851': '四訂版 図解 福祉の法律と手続きがわかる事典',
    '9784384047875': 'すぐに役立つ 入門図解最新 告訴・告発・刑事トラブル解決マニュアル',
    '9784384047905': 'すぐに役立つ 入門図解 最新 交通事故の法律とトラブル解決マニュアル',
    '9784384047936': 'すぐに役立つ 入門図解 最新 メンタルヘルスの法律問題と手続きマニュアル',
    '9784384047943': 'すぐに役立つ 最新 入門図解 相続のしくみと手続き',
    '9784384047950': '事業者必携 入門図解 最新 管理者のための労働法の基本と実務',
    '9784384047974': 'すぐに役立つ 相続法改正対応！入門図解 相続・遺言・遺産分割の法律と手続き 実践文例82',
    '9784384047981': 'すぐに役立つ 改正対応著作権・コンテンツビジネスの法律とトラブル解決マニュアル',
    '9784384048018': 'すぐに役立つ これだけは知っておきたい空き家対策の法律・税金と活用法',
    '9784384048025': '図解で早わかり 改正対応！相続・贈与のしくみと手続き',
    '9784384048032': '図解 最新 総務・人事労務・経理部門の仕事の基本がわかる事典',
    '9784384048063': '図解で早わかり 改訂新版 労働安全衛生法のしくみ',
    '9784384048070': '事業者必携 働き方改革法に対応！最新パート・高齢者雇用・派遣・請負契約の法律と手続き',
    '9784384048087': 'すぐに役立つ 最新 借地借家の法律と実務書式87',
    '9784384048117': '事業者必携 消費税率10％引き上げに対応！入門図解 会社の税金【法人税・消費税】しくみと手続き',
    '9784384048131': '事業者必携 障害福祉事業者のための障害福祉サービスと申請手続きマニュアル',
    '9784384048148': 'すぐに役立つ 財産管理【信託・成年後見・遺言】の法律知識と活用法',
    '9784384048155': '事業者必携 介護事業者のための 最新 介護福祉サービス申請手続きと書式',
    // '9784384048230': 'すぐに役立つ 入門図解 最新 介護保険【サービス・費用】と介護施設のしくみと手続き',
    '9784384048292': '図解で早わかり 共生型サービスにも対応！介護保険・障害者福祉のしくみ',
    '9784384048414': '事業者必携 入門図解 会社の終わらせ方･譲り方【解散清算･事業継承･M&A】の法律と手続き実践マニュアル',
    '9784384048544': '事業者必携 与信管理から法的手段､経理処理まで 最新 売掛金回収･債権管理の基本と対策',
    '9784474061712': 'SDGs経営の時代に求められるCSRとは何か',
    '9784502268816': '懲戒処分の基本と実務',
    '9784502366710': '図解 不祥事の社内調査がわかる本',
    '9784502392511': '不動産ファイナンスの法務と契約実務',
    '9784502409615': 'スタートアップ法務',
    '9784502470219': 'ChatGPTの法律',
    '9784535520356': '刑事裁判ものがたり',
    '9784535521117': '少年法講義',
    '9784535521629': 'リーガル・リサーチ［第５版］',
    // '9784535524248': '伝聞法則に強くなる',
    '9784535525207': '新 ケースでわかる民事訴訟法',
    '9784539729045': '改訂版 みなし配当の税務',
    '9784785714710': '独禁法基本法令',
    '9784785727659': '事業再生研究叢書18 中小企業等の健全な経営に関する新しいガイドラインの課題と展望',
    '9784785752194': '別冊商事法務No.385 事業報告記載事項の分析――平成25年6月総会会社の事例分析',
    '9784788283442': '判例にみる債務不存在確認の実務',
    '9784818512047': '実例解説 企業不祥事対応[第２版]－これだけは知っておきたい法律実務',
    '9784897619002': '経営側弁護士による精選労働判例集 第12集',
    '9784897619569': '会社が変わる！生産性が向上する！ ジョブ型人事とは何か？',

    // 2024-07-01 Wave 2 https://www.notion.so/legalscape/df23b91407ce4f81bbc57e47e6f3ca05?pvs=4#4ca1b67e7e414cc0a1c65b110454dcfd
    '9784326403363': 'ファッションロー',
    '9784326403523': '法学講義民法 総則 第3版',
    '9784326403592': '損失補償法コンメンタール',
    '9784326404070': '実務解説 独占禁止法・景品表示法・下請法 第1巻 独占禁止法編',
    '9784326404094': '再生可能エネルギー法務 改訂版',
    '9784326404179': '民法〔財産法〕講義 第2版',
    '9784384046359': '事業者必携 中小企業のための 解散・倒産・事業承継の法律と税務',
    '9784384046380': '事業者必携 最新 育児・出産・介護の法律と実践書式サンプル43',
    '9784384046403': '図解で早わかり 最新版 知的財産権のしくみ',
    '9784384046496': '事業者必携 図解と書式でわかる 給与・賞与・退職金をめぐる法律と税務',
    '9784384046717': '事業者必携 マイナンバー制度に対応！最新 就業規則と社内規程 実務マニュアル',
    '9784384046809': '事業者必携 福祉起業家のためのNPO・一般社団法人・社会福祉法人のしくみと設立登記・運営マニュアル',
    '9784384046830': '事業者必携 風営法改正に対応！飲食業開業・許認可申請手続きマニュアル',
    '9784384046922': 'すぐに役立つ 労働審判・個別労働あっせん ケース別実践書式40',
    '9784384046960':
      '事業者必携 問題社員対策から雇止め、休業休職対策まで 図解 解雇・退職勧奨の上手な進め方と法律問題解決マニュアル',
    '9784384047349': 'すぐに役立つ 入門図解 最新 成年後見のしくみと申請手続き',
    '9784384047509': '事業者必携 不動産契約基本フォーマット 実践書式80',
    '9784384047646': '事業者必携 宅建業申請から民泊、農地まで不動産ビジネスのための許認可のしくみと手続き',
    '9784384047660': '図解で早わかり 民法改正で変わる！最新 契約のしくみとルール',
    '9784384047752': '事業者必携 建設業から風俗営業、産廃、入管手続きまで 改訂新版 許認可手続きと申請書類の書き方',
    // '9784384047882': '事業者必携 改訂新版 建設業の法務と労務 実践マニュアル',
    '9784384047967': '事業者必携 改正対応 入門図解 労働時間と給与計算の法律と手続き',
    '9784384047998': '事業者必携 入門図解 中小企業経営者のための 法人税と決算書のしくみと手続き',
    '9784384048094': '事業者必携 働き方改革法に対応！就業規則の作成・見直し実践マニュアル',
    '9784384048223':
      '事業者必携 働き方改革法に対応！建設業事業者のための 最新 労務管理・安全衛生・社会保険の法律と手続き',
    '9784384048285': '事業者必携 税率10％、軽減税率制度に対応！入門図解 消費税のしくみと申告書の書き方',
    '9784384048315': '事業者必携 入門図解 職場のハラスメント【セクハラ・パワハラ・マタハラ】の法律と対策',
    '9784384048339': '事業者必携 民泊ビジネスのための手続[届出･登録･許可申請]と書式実践マニュアル',
    '9784384048346': 'すぐに役立つ 遺産分割のしくみと相続税申告書の書き方',
    '9784384048513': 'すぐに役立つ 暮らしのセーフティネット！失業等給付･職業訓練･生活保護･給付金のしくみと手続き',
    '9784384048612': 'すぐに役立つイザというときに困らない 最新 親の入院･介護･財産管理･遺言の法律入門',
    '9784384048636': '事業者必携 契約知識から下請法まで 最新 請負･業務委託･副業をめぐる法律と実務書式',
    '9784384048650': 'すぐに役立つ 最新 不動産の売却【任意売却･相続放棄･空き家処分】と債務整理の法律問題',
    '9784384048667': 'すぐに役立つ これならわかる！入門図解 障害者総合支援法と障害年金の法律知識',
    '9784384048674': '図解 最新 社会保険･労働保険の基本と手続きがわかる事典',
    '9784474019539': 'だれでもわかる企業承継の実務',
    '9784474026957': '企業の内部統制とリスクマネジメント トップダウンアプローチとリスクベースの内部統制評価',
    '9784474028364': 'クラウドビジネスと法',
    '9784474028777': '法令で読み解く新放送制度',
    '9784474028838': '詳解 改正電波法の実務',
    '9784474052819': '改訂版 解説 中小企業等協同組合会計基準',
    '9784474062610': '先生！バナナはおやつに含まれますか？ 法や契約書の読み方がわかるようになる本',
    '9784474062948': '事例と解説でよくわかる 中小企業必見！テレワーク導入・実践ガイド',
    '9784474063907': 'クレーム対応の「超」基本エッセンス 新訂版 エキスパートが実践する鉄壁の５ヶ条',
    '9784474065345': 'ビジネス法体系 競争法/独禁法',
    '9784474065833': '役員・従業員の不祥事対応の実務 調査・責任追及編',
    '9784474066243': '社員の問題行為への適正な対応がわかる本 初動対応から懲戒処分等・再発防止策の実行に至るまで',
    '9784474066625': '新訂版 基礎からわかる食品表示の法律・実務ガイドブック',
    '9784474066755': '2020年4月スタート！同一労働同一賃金 ガイドラインに沿った待遇と賃金制度の作り方',
    '9784474067424': '改正民法対応 はじめてでもわかる 売買契約書 図解とチェックリストで抜け漏れ防止',
    '9784474067851': 'フローチャートでわかる 反社会的勢力排除の「超」実践ガイドブック 改訂版',
    '9784474068230': '海外贈収賄防止コンプライアンスプログラムの作り方[改訂版]',
    '9784474068254': 'はじめての人でもよく解る！やさしく学べるビル管理の法律',
    '9784474068278': 'キャリアアップのための知財実務のセオリー 技術を権利化する戦略と実行 増補版',
    '9784474069077': '事例でわかる 自治体職員のための組織で取り組むハードクレーム対応',
    '9784474069268': 'インパクト評価と社会イノベーション ＳＤＧｓ時代における社会的事業の成果をどう可視化するか',
    '9784474071643': 'はじめての人でもよく解る！やさしく学べる化学物質管理の法律',
    '9784474072008': 'ライセンス契約のすべて 実務応用編 交渉から契約締結までのリスクマネジメント 改訂版(改正民法対応)',
    '9784474072770': '人事労務担当者の勘違い あるあるＱ＆Ａ 誤った法制度理解をしないために',
    '9784492212394': '指定管理者制度 問題解決ハンドブック',
    '9784492270578': 'スピード解説 民法＜債権法＞改正がわかる本',
    '9784492533673': '攻めのガバナンス 経営者報酬・指名の戦略的改革',
    '9784492533987': '事業担当者のための逆引きビジネス法務ハンドブック M&A契約書式編',
    '9784492534007': 'インフォメーション・ガバナンス 企業が扱う情報管理のすべて 顧客情報から社内情報まで',
    '9784492534397': '事業担当者のための逆引きビジネス法務ハンドブック 第２版',
    '9784492534458': 'ジョイント・ベンチャー戦略大全 改訂版 設計・交渉・法務のすべて',
    '9784492558010': '持株会社の実務（第9版） ホールディングカンパニーの経営・法務・税務・会計',
    '9784492654941': 'ベンチャーキャピタルの実務',
    '9784492733509': '成長と承継のための PEファンド活用の教科書',
    '9784502105418': '詳解 平成27年改正労働者派遣法 改正法の企業対応と適法な業務処理請負への切替え実務',
    '9784502115219': '商業施設賃料の理論と実務―転換期の不動産鑑定評価',
    '9784502123917': '子会社管理の法務・税務〈第２版〉',
    '9784502134517': 'ESSENCE OF LEGAL PROCEDURE 訴訟の心得 円滑な進行のために',
    '9784502154911': '配転・出向・降格の法律実務 （第２版）',
    '9784502157110': '独占禁止法の手続と実務',
    '9784502163616': '改正労働基準法の基本と実務',
    '9784502171512': '会社法務書式集〈第２版〉',
    '9784502175718': '監査等委員会設置会社の実務〈第２版〉―他制度との比較と移行手続の解説',
    '9784502176319': '現代の金融機関と法〔第5版〕',
    '9784502211416': '企業訴訟実務問題シリーズ 税務訴訟',
    '9784502230813': '企業訴訟実務問題シリーズ システム開発訴訟',
    '9784502238710': 'ライセンス契約の基本と書式',
    '9784502245510': '新・センスのよい法律文章の書き方',
    '9784502247712': '独占禁止法と損害賠償・差止請求',
    '9784502267512': '契約書作成のプロセスを学ぶ(第2版) ビジネスに寄り添う契約実務の思考法',
    '9784502274411': 'データ取引契約の基本と書式',
    '9784502274718': 'M&A の契約実務(第2版)',
    '9784502296819': '国際取引における準拠法・裁判管轄・仲裁の基礎知識',
    '9784502311314': 'Q&Aでわかる業種別法務製造',
    '9784502311512': 'Q&Aでわかる業種別法務不動産',
    '9784502311611': 'Q&Aでわかる業種別法務医薬品・医療機器',
    '9784502313813': '不動産賃貸借契約の実務入門',
    '9784502315312': '上場株式取引の法務 第2版',
    '9784502330216': 'ソフトウェア開発委託契約',
    '9784502332319': '女性活躍・ハラスメント規制法に対応! Q&A 発達障害・うつ・ハラスメントの労務対応 第2版',
    '9784502335419': 'インターネット・SNSトラブルの法務対応',
    '9784502341014': '刑事政策におけるソーシャルワークの有効性 高齢者犯罪への対応に関する日独比較研究',
    '9784502345913': 'ライセンス契約書作成のポイント',
    '9784502357213': '適切な賠償額を勝ち取る交通事故案件対応のベストプラクティス',
    '9784502366314': '企業法務入門',
    '9784502373718': '従業員をめぐる転職・退職トラブルの法務 予防＆有事対応',
    '9784502377310': 'Q&A AIの法務と倫理',
    '9784502382116': 'システム開発を成功させる IT契約の実務',
    '9784502386213': '秘密保持契約・予備的合意書・覚書の法務と書式',
    '9784502409110': '医療機器ビジネスの法律実務',
    '9784502415616': '令和２年改正個人情報保護法Ｑ＆Ａ',
    '9784502427916': 'デジタルヘルス事業参入の法務',
    '9784502434013': '契約解消の法律実務',
    '9784502437816': 'ケーススタディでわかるオンラインサービスのスタート法務',
    '9784502442414': '共同研究開発契約の法務〈第2版〉',
    '9784502448218': 'プライバシーガバナンスの教科書',
    '9784502461309': 'Strategic Mergers & Acquisitions 新版M&Aのグローバル実務〈第2版〉',
    '9784502461316': '自動車部品メーカー取引の法律実務',
    '9784502461415': '令和3年改正法対応 発信者情報開示命令活用マニュアル',
    '9784502462214': 'スマートコントラクトの仕組みと法律',
    '9784502475412': '改正法で民間企業による合理的配慮の提供が義務に 障害者差別解消法と実務対応がわかる本',
    '9784502991202': '金融商品取引法の開示制度 歴史的変遷と制度趣旨',
    '9784535521803': '基礎演習 行政法［第2版］',
    '9784535521964': '民事紛争解決の基本実務',
    '9784535522039': '刑事訴訟法入門 第2版',
    '9784535522435': '債権各論Ⅰ 契約法',
    '9784535523807': '刑事政策学',
    '9784535523951': '離婚後の共同親権とは何か 子どもの視点から考える',
    '9784535524125': 'ケーススタディ刑法【第5版】',
    // '9784535524286': '契約法 第3版 セカンドステージ債権法I',
    // '9784535524293': '債権総論 第3版 セカンドステージ債権法II',
    // '9784535524309': '事務管理・不当利得・不法行為 第3版 セカンドステージ債権法III',
    '9784535524347': '憲法学の現在地 判例・学説から探究する現代的論点',
    '9784535524460': '行政による制裁的公表の法理論',
    '9784535524484': '判例から考える行政救済法 第2版',
    '9784535524552': 'スタートライン債権法（第7版）',
    '9784535524798': '憲法II 総論・統治',
    '9784535524903': '刑事裁判は生きている 刑事事実認定の現在地',
    '9784535525153': '再犯防止から社会参加へ ヴァルネラビリティから捉える高齢者犯罪',
    '9784535525337': '原発再稼働と公法',
    '9784535525528': '政策形成訴訟における理論と実務 福島原発事故賠償訴訟・アスベスト訴訟を中心に',
    '9784535525535': '保険法と要件事実 法科大学院要件事実教育研究所報第19号',
    '9784535525658': '刑事司法改革の現段階',
    '9784535525764': '平和的生存権の展開',
    '9784535525948': '憲法パトリオティズムと現代の教育',
    '9784535525962': '最新 重要判例解説 民事訴訟法',
    '9784535526181': '刑事捜査法の研究',
    '9784535526228': '南山大学学術叢書 少年法の理論と実務',
    '9784535526426': '犯罪学におけるコントロールモデルの展開と犯罪原因論の課題',
    '9784535526631': 'プライバシーと氏名・肖像の法的保護',
    '9784535806788': '行政法',
    '9784539726228': '労働時間・残業代 裁判所の判断がスグわかる本',
    '9784539727799': '解雇 裁判所の判断がスグわかる本',
    '9784539727881': '被疑者弁護マニュアル',
    '9784539727942': '新しい労働時間・休日・休暇 法律実務ハンドブック',
    '9784539746882':
      '与党大綱・各省庁資料をベースに令和5年度税制改正のポイントと実務をQ&Aで徹底解説！ 令和５年度 よくわかる税制改正と実務の徹底対策',
    '9784641138063': '民事訴訟法・倒産法の研究',
    '9784641138339': '民法概論4 債権各論',
    '9784641243200': '不正競争防止法〔第2版〕',
    '9784641243439': '意匠法〔第2版〕',
    '9784641707641': '現代訴訟の論点と法理論の検討［連載誌面合本版］',
    '9784641707887': '働き手・働き方の多様化と労働法［連載誌面合本版］',
    '9784641707894': '基礎法学のススメ ［連載誌面合本版］',
    '9784641708181': '民事保全・執行を勉強しよう――実務との架橋を目指して ［連載誌面合本版］',
    '9784641710405': '知的財産法とビジネスの種〔第1期〕〔第2期〕 ［連載誌面合本版］',
    '9784785712037': '一問一答新不動産登記法',
    '9784785717742': '事業再生ナビゲーション',
    '9784785718046': '一問一答シリーズ 一問一答 動産・債権譲渡特例法〔三訂版増補〕',
    '9784785718732': 'Q&A 震災と株主総会対策',
    '9784785719111': 'ビジネス契約書の起案・検討のしかた〈第２版〉',
    '9784785719784': '国際契約実務のための予防法学 : 準拠法・裁判管轄・仲裁条項',
    '9784785719883': '裁判実務シリーズ 1 労働関係訴訟の実務',
    '9784785719975': '概説倒産と労働',
    '9784785720322': '企業買収と防衛策',
    '9784785720834': '法務部門の実用知識',
    '9784785720957': '私的整理 : 88講による道案内',
    '9784785720964': '民法改正のいま : 中間試案ガイド',
    '9784785721343': '会社法判例インデックス',
    '9784785721374': '実務借地借家法〔新訂第3版〕',
    '9784785721411': '遺言執行の手引',
    '9784785721503': '倒産判例インデックス〔第３版〕',
    '9784785721510': '労働判例インデックス〔第3版〕',
    '9784785722791': '森・濱田松本法律事務所 シリーズ改正会社法 Q&Aグループガバナンスの実務',
    '9784785723101': 'M&Aにおける第三者委員会の理論と実務',
    '9784785723590': '番号利用法 : マイナンバー制度の実務',
    '9784785725303': 'コーポレートガバナンス・コードのすべて',
    '9784785727307': '裁判実務シリーズ11 破産実務の基礎',
    '9784785727451': '消費者相談マニュアル〔第4版〕',
    '9784785727536': 'ヘルステックの法務Q&A',
    '9784785727963': '2020年 個人情報保護法改正と実務対応',
    '9784785728465': '「個人データ」ビジネス利用の極意',
    '9784785728533': '取締役会事務局の実務──コーポレート・ガバナンスの支援部門として',
    '9784785729806': 'BtoC Eコマース実務対応',
    '9784785729936': '海外子会社管理の法実務―コンプライアンス体制構築の技法',
    '9784785730000': '犯罪被害者救済便覧',
    '9784785730017': 'メンタルヘルスの諸問題と企業実務',
    '9784785730024': 'ガイドブック AI・データビジネスの契約実務',
    '9784785730321': '詳解 改正民法・改正不登法・相続土地国庫帰属法',
    '9784785730338': '発電プロジェクトの契約実務〔第2版〕',
    '9784785730352': '「ビジネスと人権」の実務',
    '9784785752101': '別冊商事法務No.376 投資信託法制の現状と展望',
    '9784785752651': '別冊商事法務№431 財務・非財務情報の実効的な開示――ESG投資に対応した企業報告',
    '9784788274051': '〔三訂版〕嘱託登記の実務',
    '9784788281165': 'スクイーズ・アウトと株価決定の実務',
    '9784788281714': '労務専門弁護士が教えるSNS・ITをめぐる雇用管理 : Q&Aとポイント・書式例',
    '9784788284333': '〔改訂版〕建築設計･施工 クレーム対応マニュアル',
    '9784788284357': '〔平成30年度税制改正対応版〕目的別 生前贈与のポイントと活用事例',
    '9784788285286': '不動産取引における 傾斜地・がけ地・擁壁の法律と実務',
    '9784788285743': 'Ｑ＆Ａと事例 物損交通事故 解決の実務',
    '9784788285798': '判例・裁決例にみる 評基通によらない財産評価－「特別の事情」の存否－',
    '9784788286399': '遺産相続事件処理マニュアル',
    '9784788286405': '相続土地評価実務マニュアル',
    '9784788286603': 'ケース別 特殊な遺言条項 作成と手続のポイント－補充事項・付言事項、祭祀承継等－',
    '9784788286702': '改正民法対応 各種契約書見直しのポイント',
    '9784788286719': '相続の限定承認－法務・税務・登記－',
    '9784788286917': '実務家が陥りやすい 借地借家の落とし穴',
    '9784788287846': '隣地をめぐるトラブル予防・解決文例集－筆界・所有権界、道路・通路、近隣紛争－',
    '9784797227246': 'プラクティス知的財産法Ⅰ〈特許法〉',
    '9784797269185': '遠隔講義消費者法〈新訂第3版〉2022',
    '9784797280586': '商法総則・商行為法（第3版）',
    '9784818511026': '労働判例に見る危機管理対応 震災・災害時の基本実務',
    '9784818514119': '統合人事管理 グローバル化対応の法律実務',
    '9784818516090': '営業秘密防衛Q&A 内部不正による情報漏洩リスクへの実践的アプローチ',
    '9784818517059': '担当者必携 障害者雇用入門 雇用のプロセスから法的構成まで',
    '9784818519091': 'テレワーク導入の法的アプローチ トラブル回避の留意点と労務管理のポイント',
    '9784818519275': '事例に学ぶ サイバーセキュリティ 多様化する脅威への対策と法務対応',
    '9784818519282': '同一労働同一賃金Q&A［第３版］──ガイドライン・判例から読み解く',
    '9784897613994': '経営側弁護士による精選労働判例集 第１集',
    '9784897614007': '経営側弁護士による精選労働判例集 第２集',
    '9784897614434': 'まる分かり平成24年改正高年齢者雇用安定法〔運用指針確定版〕',
    '9784897614632': '経営側弁護士による精選労働判例集 第３集',
    '9784897614809': 'まる分かり平成25年改正障害者雇用促進法〔速報版〕',
    '9784897615059': '経営側弁護士による精選労働判例集 第4集',
    '9784897615097': 'まる分かり平成26年改正雇用保険法〔速報版〕',
    '9784897615110': '労務管理は負け裁判に学べ なぜ負けたのか？どうすれば勝てたのか？',
    '9784897615172': 'まる分かり平成26年改正労働安全衛生法〔速報版〕',
    '9784897615608': '経営側弁護士による精選労働判例集 第5集',
    '9784897615660': '訴訟リスクを劇的にダウンさせる就業規則の考え方、作り方。',
    '9784897615974': 'まる分かり平成28年改正雇用保険法・育児介護休業法〔速報版〕',
    '9784897616049': '経営側弁護士による精選労働判例集 第6集',
    '9784897616391': 'まる分かり平成28年改正外国人技能実習制度〔速報版〕',
    '9784897616476': '本書を読まずに障害者を雇用してはいけません！',
    '9784897616537': 'まる分かり平成29年改正雇用保険法・育児介護休業法・職業安定法〔速報版〕',
    '9784897617152': 'まる分かり平成30年働き方改革関連法〔改正労基法編〕',
    '9784897617176': 'まる分かり平成30年働き方改革関連法〔同一労働同一賃金編〕',
    '9784897617213': '労務管理は負け裁判に学べ！2 なぜ負け続けるのか？これが最後の負け戦!',
    '9784897617534': 'まる分かり2019年施行入管法～特定技能資格の創設～',
    '9784897617596': '経営側弁護士による精選労働判例集 第９集',
    '9784897618425': '新 採用戦略ハンドブック',
    '9784897618470': '退職勧奨と雇用調整の超実務',
    // '9784897618531': '労災保険適用事業細目の解説 令和3年版',
    '9784897618579': 'LGBTと労務',
    '9784897618593': '人事担当者、社労士に贈る 知っておきたい 合同労組・ユニオン対応の基礎と実践 改訂第2版',
    '9784897618654': '経営側弁護士による精選労働判例集 第11集',
    '9784897618678': 'まる分かり令和3年施行育児介護休業法・雇用保険法〔速報版〕',
    '9784897618944': 'これからの高年齢者雇用・対応の実務',
    '9784897619040': 'これで解消！医療機関の9つの労務リスク',
    '9784897619293': '労働裁判における和解の実際',
    '9784897619330': '労働実務事例研究 2023年版',
    '9784897619408': '元監督署長が解説 これならわかる自動車運転者の改善基準Ｑ＆Ａ 改訂第２版',
    '9784897619422': '離職防止に向けた働き方改革の実践ガイド',

    // 2024-07-19 Wave 3 https://www.notion.so/legalscape/df23b91407ce4f81bbc57e47e6f3ca05?pvs=4#f46c584ecf234f6e8af9ed874689bdbd
    '4641016127': '注釈民法 第１２巻 債権(３) 債権の消滅 474条～520条 復刊版',
    '9784000612227': '金融法講義 新版',
    '9784326403264': '著作権法詳説 判例で読む14章 [第10版]',
    '9784326403547': '逐条講義 製造物責任法 第2版 基本的考え方と裁判',
    '9784326403967': 'オープンイノベーションの知財・法務',
    '9784326449651': '家事法の理論・実務・判例2',
    '9784384046298': '図解で早わかり 最新版 契約のしくみと契約書作成の基本',
    '9784384046311': '事業者必携 採用退職から税務申告・移転・変更登記まで小さな会社の「事務」と「書式」サンプル集129',
    '9784384046458': '図解で早わかり 最新 税金のしくみ',
    '9784384046465': '図解 最新 相続・贈与・財産管理の法律と税金がわかる事典',
    '9784384046595': '図解で早わかり 最新 総務・人事・労務の法律と手続き',
    '9784384046601': '事業者必携 平成27年労働者派遣法改正対応！最新 パート・派遣・請負をめぐる法律知識',
    '9784384046625': '事業者必携 帳簿のつけ方から届出・申告・経費処理まで 入門図解個人開業・青色申告のしくみと手続き',
    '9784384046649': '図解で早わかり マイナンバー対応経理のしくみ',
    '9784384046687': '重要事項＆用語 図解 最新 不動産契約基本法律用語辞典',
    '9784384046724': '図解で早わかり 最新 労働安全衛生法のしくみ',
    '9784384046939': 'すぐに役立つ 知っておきたい 最新 図解建築基準法と消防法のしくみ',
    '9784384047332': '事業者必携 入門図解 改正対応！特定商取引法・景品表示法のしくみと対策',
    '9784384047363': '重要事項＆用語 図解 トラブル解決に役立つ 最新 民事訴訟・執行・保全 基本法律用語辞典',
    '9784384047479': '図解で早わかり 最新 刑事訴訟法のしくみ',
    '9784384047523': '図解 最新 ネットビジネスの法律とトラブル解決法がわかる事典',
    '9784384047547': 'すぐに役立つ 入門図解 民法【債権法】大改正',
    '9784384047554': '事業者必携 これならわかる 最新 不動産業界の法務対策',
    '9784384047615': '図解で早わかり 債権法改正に対応！民法【財産法】のしくみ',
    // '9784384047639': '図解 最新 税金のしくみと手続きがわかる事典',
    '9784384047677':
      'すぐに役立つ 図解とQ&Aでわかる 最新 個人情報保護法と秘密保持契約をめぐる法律問題とセキュリティ対策',
    // '9784384047691': '図解で早わかり 最新 刑法のしくみ',
    // '9784384047707': '図解で早わかり 改訂新版 消費者契約法・特定商取引法・割賦販売法のしくみ',
    // '9784384047745': '図解で早わかり 最新 賃貸借のしくみとルール',
    '9784384047790': '重要事項＆用語 図解 民法改正で変わる！最新 契約実務 基本法律用語辞典',
    '9784384047820': 'すぐに役立つ 図解とQ&Aでわかる 最新労働安全衛生をめぐる法律と疑問解決マニュアル108',
    '9784384047837': '図解で早わかり 民法改正対応！最新 土地・建物の法律と手続き',
    '9784384047912': '事業者必携 入門図解 最新 中小企業のための会社法務の法律常識と実務ポイント',
    '9784384048001': '事業者必携 入門図解 最新 はじめての金融商品取引法',
    '9784384048049': '図解で早わかり 働き方改革法に対応！最新 給与計算事務のしくみと手続き',
    '9784384048056': '図解で早わかり 最新 働き方改革法と労働法のしくみ',
    '9784384048278': '図解で早わかり 最新 社会保険・労働保険のしくみと手続き',
    '9784384048322': 'すぐに役立つ 図解とQ&Aでわかる 最新入管法と外国人雇用の法律問題解決マニュアル',
    '9784384048391': '図解で早わかり 働き方改革法､会社法改正に対応! 最新 会社法務の基本と実務',
    '9784384048407':
      '事業者必携 改正対応！中小企業のための株式会社【株主総会･取締役会・監査役会】の議事録･登記の手続きと書式',
    '9784384048421': '図解で早わかり 改正対応！民事執行法･民事保全法のしくみと手続き',
    '9784384048438': '事業者必携 入門図解 テレワーク･副業兼業の法律と導入手続き実践マニュアル',
    '9784384048445': '図解で早わかり 改正対応！株主総会のしくみと手続き',
    '9784384048452': '図解で早わかり 改正対応！民法のしくみと手続き',
    '9784384048469': '事業者必携 70歳雇用延長に対応！入門図解 高年齢者雇用安定法の知識',
    '9784384048483': '事業者必携 入門図解 危機に備えるための 解雇･退職･休業･助成金の法律と手続き',
    '9784384048490': '図解で早わかり 会計の基本と実務',
    '9784384048506': '図解で早わかり 最新 会社の倒産 しくみと手続き',
    '9784384048537':
      'すぐに役立つ 財産分与から慰謝料･養育費･親権･調停･訴訟まで 最新 離婚の法律相談と手続き 実践マニュアル',
    '9784384048551': '図解で早わかり 最新 医療保険･年金･介護保険のしくみ',
    '9784384048568': 'すぐに役立つ はじめての人でも大丈夫！ 最新 個人開業･青色申告の基本と手続き 実践マニュアル',
    '9784384048575': '事業者必携 はじめての人でもできる！株式会社の変更登記と手続き 実務マニュアル',
    '9784384048605': '図解で早わかり 最新 人事･労務の基本と実務',
    '9784384048629': '図解で早わかり 最新 会社の税金',
    '9784474033474': '投資家と企業の対話 -次世代の企業価値コミュニケーションと統合報告-',
    '9784474057739': '成功する精神障害者雇用 ～受入準備・採用面接・定着支援～',
    '9784474058460': '今日からはじめる無期転換ルールの実務対応 多様な社員の活かし方',
    '9784474059399': 'ビジュアルデザイン発注時に知っておきたい！著作権のキホン トラブルを未然に防ぐ対策Ｑ＆Ａ',
    '9784474061729': 'ビジネス法体系 企業取引法',
    '9784474063105': 'ビジネス法体系 知的財産法',
    '9784474063976': 'ビジネス法体系 国際ビジネス法',
    '9784474064126': 'マネー・ローンダリング反社会的勢力対策ガイドブック 2018年金融庁ガイドラインへの実務対応',
    '9784474064157': 'スキルアップのための企業法務のセオリー 実務の基礎とルールを学ぶ',
    '9784474064164': 'レベルアップをめざす 企業法務のセオリー 応用編 一段上の実務とマネジメントの基礎を学ぶ',
    '9784474065338': '不正事例で基礎から学ぶ コーポレートガバナンス新時代の内部統制',
    '9784474066465': '事業拡大・設備投資・運転資金の着実な調達 ベンチャー企業が融資を受けるための法務と実務',
    '9784474068049': 'すぐに使える！事例でわかる！外国人実習・雇用実戦ガイド[第2版]',
    '9784474068834':
      '法務・知財パーソンのための契約交渉のセオリー 交渉準備から契約終了後までのナレッジ 改訂版 民法改正対応',
    '9784474071995': 'ライセンス契約のすべて 基礎編 ビジネスリスクの法的マネジメント 改訂版(改正民法対応)',
    '9784474091283': 'ＩＳＯ環境法クイックガイド２０２３',
    '9784492533895': '企業統治と成長戦略',
    '9784492533918': 'ガバナンス革命の新たなロードマップ ２つのコードの高度化による企業価値向上の実現',
    '9784492533963': '企業法とコンプライアンス 第3版 “法令遵守”から“社会的要請への適応”へ',
    '9784492534526': '初級 ビジネスコンプライアンス 第3版 「社会的要請への適応」から事例理解まで',
    '9784502099007': '募集株式と種類株式の実務〔第2版〕',
    '9784502144516': '新・会社法実務問題シリーズ/8 会社の計算〈第2版〉',
    '9784502195914': '労働条件変更の基本と実務',
    '9784502231919': '企業訴訟実務問題シリーズ 会社法訴訟―株主代表訴訟・株式価格決定',
    '9784502293214': '「法務の技法」シリーズ法務の技法',
    '9784502308413': '企業グループの経営と取締役の法的責任',
    '9784502311116': 'Q&Aでわかる業種別法務銀行',
    '9784502349119': 'ハラスメント防止の基本と実務',
    '9784502349416': '実務家のための医療法人法医療法 第6章 逐条解説',
    '9784502356711': 'ＡＩ・データ関連契約の実務',
    '9784502369018': 'Q&Aでわかる業種別法務学校',
    '9784502375613': '新・会社法実務問題シリーズ・3 新株予約権・社債(第3版)',
    '9784502387517': '新・会社法実務問題シリーズ・5 機関設計・取締役・取締役会(第2版)',
    '9784502388019': '株主間契約・合弁契約の実務',
    '9784502434518': '初めての人のための契約書の実務',
    '9784502465116': 'テーマ別 ヘルスケア事業の法律実務',
    '9784535002296': '民事判例Ⅰ 2010年前期',
    '9784535002319': '民事判例3 2011年前期',
    '9784535002371': '民事判例Ⅸ 2014年前期',
    '9784535403529': '別冊法学セミナー 新・総合特集シリーズ12 ヘイトスピーチとは何か―民族差別被害の救済',
    '9784535518629': '判例特別刑法',
    '9784535520608': '憲法I 基本権',
    '9784535520615': '判例特別刑法［第2集］',
    '9784535520813': 'スタートライン民法総論［第3版］',
    '9784535521254': '簡裁民事訴訟マニュアル',
    '9784535521636': '講義再現版第5版 伊藤真の刑事訴訟法入門',
    '9784535522442': '債権各論Ⅱ 事務管理・不当利得・不法行為',
    '9784535522718': '刑事訴訟法における学説と実務──初学者のために',
    '9784535522879': '刑事法廷弁護技術',
    '9784535522909': '検証・自動車運転死傷行為等処罰法',
    '9784535523081': '労働法',
    '9784535523135': '消費者法講義［第5版］',
    '9784535523197': '判例特別刑法［第3集］',
    '9784535523302': '破産から新民法がみえる 民法の盲点と破産法入門',
    '9784535523333': '基本行政法 第3版',
    '9784535523388': '会社法の学び方',
    '9784535523647': '特定商取引法ハンドブック［第６版］',
    '9784535524002': '基本に学ぶ憲法',
    '9784535524040': '判例でみる 音楽著作権訴訟の論点80講',
    '9784535524200': '基本刑事訴訟法Ⅱ 論点理解編',
    '9784535524279': '起訴前・公判前整理・裁判員裁判の弁護実務',
    '9784535524545': '先端刑法総論 現代刑法の理論と実務',
    '9784535525122': '事例研究 行政法［第4版］',
    '9784535525221': '初歩からはじめる物権法',
    '9784535525771': '債権法改正と判例の行方 新しい民法における判例の意義の検証',
    '9784535525832': '民事裁判ICT化論の歴史的展開',
    '9784535526006': '土地所有を考える 所有者不明土地立法の理解を深めるために',
    '9784535526235': '物権法 物権・担保物権',
    // '9784535526266': '基礎刑事訴訟法',
    '9784535526273': '交渉から訴訟へ 交渉理論からみた民事訴訟',
    '9784535526488': '行政訴訟と要件事実',
    '9784535526495': '基礎トレーニング倒産法[第2版]',
    '9784535527119': '憲法Ⅰ基本権 第２版',
    '9784535806900': '刑法Ⅰ 総論',
    '9784539726778': '改訂版 労使協定・労働協約 完全実務ハンドブック',
    '9784539727652': '〔パワハラ・セクハラ〕 裁判所の判断がスグわかる本',
    '9784539728154': '新債権法に基づく 建設工事請負契約約款作成の実務',
    '9784539728178': '法人税 損失計上マニュアル',
    '9784539728192': '改訂版 会社法・租税法からアプローチする 非上場株式評価の実務',
    '9784539728727': '労務不祥事の社内調査ハンドブック',
    '9784539728956': '改訂版 弁護士のための イチからわかる交通事故対応実務',
    '9784539729106': '水町詳解労働法 公式読本 理論と実務でひも解く労働法 Q&A300',
    '9784641046870': '分野別 国際条約ハンドブック',
    '9784641137066': '民事再生法入門〔第2版〕',
    '9784641138629': '令和元年 改正会社法',
    '9784785719968': '解説原子力損害賠償支援機構法 : 原子力損害賠償制度と政府の援助の枠組み',
    '9784785723187': '一問一答 平成26年改正会社法〔第2版〕',
    '9784785724764': '募集株式発行の法と実務',
    '9784785725990': '不適切会計対応の実務 ―予防・発見・事後対応',
    '9784785726072': 'エッセンス景品表示法',
    '9784785726324': '詳解 改正民法',
    '9784785728243': 'プラットフォームビジネスの法務',
    '9784785728434': '令和元年 会社法改正と実務対応',
    '9784785728779': '独禁法の授業をはじめます',
    '9784785728816': '基礎から読み解く社外取締役の役割と活用のあり方',
    '9784785728847': '監査役・監査等委員・監査委員ハンドブック',
    '9784785728922': '株式実務担当者のための会計・金商法・税法の基礎知識',
    '9784785728939': '株主総会資料電子提供の法務と実務',
    '9784785728946': '債権法の未来 改正が見送られた重要論点',
    '9784785729318': 'NFTゲーム・ブロックチェーンゲームの法制',
    '9784785729356': '実務問答会社法',
    '9784785729523': '新株予約権ハンドブック 第5版',
    '9784785729646': '契約法の現代化 契約法の現代化Ⅲ 債権法改正へ',
    '9784785729776': '取締役会等の意思決定援助 会計的アプローチから',
    '9784785729813': '会社法 実務問答集Ⅳ',
    '9784785729899': '実例解説 相場操縦事件――公正な市場形成のために',
    '9784785730116': '事業再生と財産評定の実務',
    '9784785730178': '法律相談 個人情報保護法',
    '9784785730314': 'ザ・コントラクト 新しい契約実務の提案',
    '9784788282988': '〔補訂版〕利益相反行為の登記実務',
    '9784788283046': '審判例にみる 家事事件における事情変更',
    '9784788285996': '土壌汚染土地をめぐる法的義務と責任',
    '9784788286054': '働き方改革関連法完全対応 就業規則等整備のポイント―改正法と実務解説・規程例―',
    '9784788286160': '相続登記相談対応マニュアル',
    '9784788286283': '〔改訂版〕裁判上の各種目録記載例集－当事者目録、物件目録、請求債権目録、差押・仮差押債権目録等－',
    '9784788287471': 'シミュレーションでみる 遺産分割方法のメリット・デメリット',
    '9784788288386': 'Ｑ＆Ａ 企業における多様な働き方と人事の法務',
    '9784788288867': 'ヒアリングシートを活用した 離婚相談 聴取事項のチェックポイント',
    '9784788288874': 'Ｑ＆Ａ ドローンの法律知識－規制・事故責任・トラブル対応等－',
    '9784788288935': '実務家が陥りやすい 破産管財の落とし穴',
    '9784788289420': '抗告・異議申立ての実務',
    '9784788289567': '実務家が押さえておきたい 事業承継対策のリスクと対応',
    '9784788289581': '自動運転・運転支援と交通事故賠償責任',
    '9784788289598': '就業規則からみる メンタル不調の予防と対応ー規制整備のポイントー',
    '9784788289680': '株式会社・各種法人別 清算手続と書式',
    '9784797227253': 'プラクティス知的財産法Ⅱ〈著作権法〉',
    '9784818519237':
      '[改訂増補]パワハラ・セクハラ・マタハラ相談はこうして話を聴く-こじらせない! 職場ハラスメントの対処法',
    '9784818519336': '待ったなし! BCP[事業継続計画]策定と見直しの実務必携 水害、地震、感染症から経営資源を守る',
    '9784818519411': '[環境・気候変動]情報開示ルールの潮流 規制と市場動向によるサステナビリティ経営の深化',
    // '9784890170098': '企業労働法実務入門―はじめての人事労務担当者からエキスパートへ',
    '9784897613628': 'トラック運送業者のための労働実務Q＆A―平成22年改正労基法対応版―',
    '9784897614281': 'まる分かり平成24年改正労働者派遣法〔施行細則確定版〕',
    '9784897614328': 'まる分かり平成24年改正労働契約法〔速報版〕',
    '9784897614342': '事例で知る 労働審判制度の実際',
    '9784897614748': '労働実務事例研究 平成25年版',
    '9784897615158': '労働実務事例研究 平成26年版',
    '9784897615707': '労働実務事例研究 平成27年版',
    '9784897616148': '労働実務事例研究 平成28年版',
    '9784897616575': '経営側弁護士による精選労働判例集 第７集',
    '9784897616704': '労働実務事例研究 平成29年版',
    '9784897617091': '労働実務事例研究 平成30年版',
    '9784897617787': 'Ｍ＆Ａと統合プロセス 人事労務ガイドブック',
    '9784897617909': '職務分析・職務評価の基礎講座 同一労働同一賃金を実現するために',
    '9784897618050': 'まる分かり2020年改正労働基準法・雇用保険法・労災保険法・高年齢者雇用安定法〔速報版〕',
    '9784897618111': '労働実務 事例研究',
    '9784897618135': '建設業における 知って得する「示談」の進め方 改訂2版',
    '9784897618173': '経営側弁護士による精選労働判例集 第10集',
    '9784897618289': 'ケーススタディ 面接シナリオによるメンタルヘルス対応の実務',
    '9784897618401': '自然災害発生・感染症流行時の労務リスク低減のポイント',
    '9784897618739': '図解 安全衛生法要覧 改訂第6版',
    '9784897618982': '労働実務事例研究 2022年版',
    '9784897619064': '実務に即した労災認定の考え方と申請のポイント 改訂第2版',
    '9784897619095': 'モデル就業規則作成・変更ガイドブック 条文対応根拠と解説 用字用語の使い方例',
    // '9784897619187': '労災保険適用事業細目の解説 令和5年版',
    '9784897619354': '精選 労働判例集 第13集',
    '9784897619545': '実践Ｑ＆Ａ方式 人材派遣の実務',
    '9784909712035': '詳説 個人情報保護法',
    '9784909712066': '詳説 次世代医療基盤法',
    '9784909712080': '詳説 薬機法 第5版 令和の大改正法',
    '9784990615567': '詳説 再生医療法',
    '9784384049275': '事業者必携 入門図解 最新 ネットビジネス運営のための法律と手続き',
    '9784384049305': '事業者必携 最新 入管法・出入国管理申請と外国人雇用の法律知識',
    '9784803728019': '家事事件重要判決50選',
    '9784535521377': '基本憲法I---基本的人権',
    '9784535527577': '医事法講義 第2版',
    '9784766424041': '環境法の考えかた Ⅰ',
    '9784766425369': '災害復興法学Ⅱ',
    '9784766426014': '新・考える民法Ⅱ　物権・担保物権',
    '9784766426106': '刑事法実務の基礎知識　特別刑法入門 2',
    '9784766426298': '家族法改正を読む',
    '9784766426472': '大学生のための日本国憲法入門',
    '9784766426670': '民事訴訟における当事者の主張規律',
    '9784766426687': '新・考える民法Ⅲ　債権総論',

    // 2024-08-01 Wave 4 https://www.notion.so/legalscape/df23b91407ce4f81bbc57e47e6f3ca05?pvs=4#dc39ffd27bf9422e8d658240519f7b71
    // '9784000248907': '民事訴訟法 第3版',
    '9784326403059': '著作権法コンメンタール1 [第2版] 1条～25条',
    '9784326403066': '著作権法コンメンタール2 ［第2版］ 26条～88条',
    '9784326403073': '著作権法コンメンタール3 [第2版] 89条～124条、附則、著作権等管理事業法',
    '9784326403219': '勁草法律実務シリーズ 消費者行政法 安全・取引・表示・個人情報保護分野における執行の実務',
    '9784326403295': '臨床実務家のための家族法コンメンタール 民法相続編',
    '9784326403387': '勁草法律実務シリーズ 最新判例にみるインターネット上のプライバシー・個人情報保護の理論と実務',
    '9784326403486': '職務発明の実務Q&A',
    '9784326403684': '少数株主権等の理論と実務 勁草法律実務シリーズ',
    '9784326403691': '金融商品取引法の理論・実務・判例 勁草法律実務シリーズ',
    '9784326403769': '実務解説 行政訴訟 勁草法律実務シリーズ',
    '9784326404001': '著作権法コンメンタール別冊平成30年・令和2年改正解説',
    '9784384046342': 'すぐに役立つ 図解とQ&Aで納得 損害賠償・慰謝料をめぐる法律とトラブル解決法165',
    // '9784384046373': 'すぐに役立つ 図解とQ&Aで納得 改正対応！会社役員をめぐる法律とトラブル解決法158',
    '9784384046410':
      'すぐに役立つ 図解とQ&Aでわかる 最新 ネットトラブルの法律知識とプロバイダへの削除依頼・開示請求の仕方',
    '9784384046427': 'すぐに役立つ 図解とQ&Aでわかる 不動産をめぐる税金の知識と疑問解決マニュアル111',
    '9784384046434': '図解で早わかり 最新版 訴訟のしくみ',
    // '9784384046502': '図解で早わかり 行政法のしくみ',
    '9784384046663': 'すぐに役立つ 図解とQ&Aでわかる 最新版 医療保険・介護保険・年金の知識と疑問解決マニュアル157',
    '9784384046670': '事業者必携 個人情報保護法改正に対応！最新請負・業務委託・下請契約の法律と書式40',
    '9784384046823': '事業者必携 個人情報保護法、特許法の改正に対応！入門図解 最新 IT企業の法務対策',
    // '9784384046861': 'すぐに役立つ 図解とQ&Aでわかる セクハラ・パワハラ・マタハラをめぐる法律とトラブル解決法130',
    '9784384046878': 'すぐに役立つ 図解とQ&A マンション管理の法律とトラブル解決マニュアル',
    '9784384046892': 'すぐに役立つ 図解とQ&Aでわかる道路・境界・建築・住環境の法律とトラブル解決マニュアル140',
    '9784384046946': 'すぐに役立つ 入門図解 交通事故の過失割合 ケース別288',
    '9784384046953': 'すぐに役立つ 図解とQ&Aでわかる パート・契約社員・派遣社員の法律問題とトラブル解決法',
    '9784384047370': 'すぐに役立つ 図解とQ&Aでわかる 賃貸トラブル解決マニュアル',
    '9784384047653': '事業者必携 入門図解 最新 独占禁止法・景表法・下請法のしくみ',
    '9784384047806': 'すぐに役立つ 図解とQ&Aでわかる 金銭貸借・クレジット・ローン・保証の法律とトラブル解決法128',
    '9784384047813':
      'すぐに役立つ 民法改正対応！図解とQ&Aでわかる 賃貸経営のための不動産賃貸・管理の法律とトラブル実践的解決法150',
    '9784384047899': 'すぐに役立つ 図解とQ&Aでわかる 住宅宿泊事業法のしくみと民泊の法律問題解決マニュアル',
    '9784384047929': '事業者必携 IT法務の法律と実践ビジネス書式',
    '9784384048261': 'すぐに役立つ 最新 建築基準法と私道・境界・日照権の法律とトラブル解決法',
    '9784384048308': 'すぐに役立つ 図解とＱ＆Ａでわかる 特定商取引法と消費者取引の法律問題トラブル解決法',
    '9784384048353': '図解 最新 契約の基本と実務がわかる事典',
    '9784384048360': '事業者必携 債権回収の切り札！改正対応 最新 民事執行の法律と書式',
    '9784384048377':
      '事業者必携 採用･退職手続きから議事録､届出､登記まで 会社の事務手続き【社会保険･労務･経理･登記】と書式実践マニュアル',
    '9784384048476': 'すぐに役立つ 法改正に対応！最新 内容証明郵便実践文例集200',
    '9784384048599': 'すぐに役立つ 債権回収から継続取引､遺言､信託まで 改正対応 公正証書のしくみと実践書式集',
    '9784384048681': 'すぐに役立つ これだけは知っておきたい！最新 暮らしの税金 しくみと手続き',
    '9784384048698': 'すぐに役立つ 入門図解 最新 マンション管理の法律と実務',
    '9784384048704': '図解 最新 労働安全衛生法の基本と実務がわかる事典',
    '9784384048711': 'すぐに役立つ 泣き寝入り無用！職場のトラブルをめぐる法律問題と実践解決書式',
    '9784384048728': '図解 最新 不動産登記の基本と実務がわかる事典',
    '9784384048735': '五訂版 図解 福祉の法律と手続きがわかる事典',
    '9784384048766': '図解 経理の基本と実務がわかる事典',
    '9784384048773': '図解 労務管理の基本と実務がわかる事典',
    '9784384048780': 'すぐに役立つ 図解とQ&Aでわかる 著作権の法律問題とトラブル解決法',
    '9784384048797': '図解で早わかり 最新 相続登記のしくみ',
    '9784384048803': '事業者必携 訪問販売･通信販売など活用自在！最新 特定商取引法と消費者契約の実践法律知識',
    '9784384048810': '事業者必携 入門図解 休業･休職の法律知識と事務手続き',
    '9784384048827': 'すぐに役立つ 相続登記･相続税･事業承継の法律と書式',
    '9784384048834': '図解で早わかり 最新 知的財産権の基本と実務',
    '9784384048841': '図解で早わかり 最新 インターネットの法律とトラブル対策',
    '9784384048865': '図解で早わかり 最新 不動産取引の法律',
    '9784384048889': 'すぐに役立つ 図解とQ&Aでわかる 最新 高年齢者雇用安定法をめぐる法律問題',
    '9784384048896': '事業者必携 これだけは知っておきたい 最新 労務管理の法律と申請書式',
    '9784384048919': '図解で早わかり 最新 税金の基本と実務',
    '9784384048926': '事業者必携 最新 出産･育児･介護のための休業･休暇の法律手続きと実務書式',
    '9784384048933': '図解で早わかり 最新 不動産登記の基本と実務',
    '9784384048940': '事業者必携 最新 建設業法と建設業許可申請のしくみと手続き',
    '9784384049022': '事業者必携 三訂版 建設業の法務と労務 実践マニュアル',
    '9784384049039': 'すぐに役立つ 改訂新版 入門図解 強制執行のしくみと手続き ケース別実践書式33',
    '9784384049053': 'すぐに役立つ 入門図解 親子の法律問題[離婚･親子関係･いじめ･事故･虐待]解決の知識',
    '9784384049077': '事業者必携 労務リスクを減らすための 入門図解 労務管理の法律知識 実践マニュアル',
    '9784384049084': '事業者必携 入門図解 社会保険･労働保険のしくみと実務ポイント',
    '9784384049091': 'すぐに役立つ 図解とQ&Aでわかる 正社員以外[パート･派遣･副業･高年齢者雇用]の働き方をめぐる法律問題',
    '9784384049107': 'すぐに役立つ 入門図解 最新 法人税のしくみと法人税申告書の書き方',
    '9784384049114': 'すぐに役立つ 入門図解 三訂版 仮差押･仮処分の法律と手続き',
    '9784384049121': '事業者必携 入門図解 介護施設の法律問題･施設管理と介護サービス申請手続き',
    '9784474053359': '第二次改訂版 中小企業等協同組合法逐条解説',
    '9784474057043': 'IoTビジネスを成功させるための法務入門',
    '9784474057920': '製造も広告担当も知っておきたい 景品表示法対応ガイドブック',
    '9784474059160': '仕事と介護の両立をサポート！ 介護に直面した従業員に人事労務担当者ができるアドバイス',
    '9784474064201': '事例で分かる外食・小売り業の労務戦略',
    '9784474064317': '現場のプロが教える 情報漏えい対応のリアル 漏えい事故 実態調査と最新事例',
    '9784474064713': '改訂版 よくわかるエコアクション21 Q＆A 基本から実務まで',
    '9784474065017': 'SNS公式アカウント運営者のための企業の信頼失墜を防ぐ 法的リスク・炎上対策',
    '9784474066304': 'リスクマネジメントのプロセスと実務 増補版',
    '9784474072282': 'はじめての人でもよく解る！ やさしく学べる危険物関係の法律',
    '9784492602256': 'スタンダード管理会計(第2版)',
    '9784502117817': 'M&Aを成功に導く 財務デューデリジェンスの実務 第4版',
    '9784502274213': '業種別 法務デュー・ディリジェンス実務ハンドブック',
    '9784502311215': 'Q&Aでわかる業種別法務証券・資産運用',
    '9784502311710': 'Q&Aでわかる業種別法務自治体',
    '9784502315619': '企業法務のための金融商品取引法',
    '9784502355219': '労働者派遣法の基本と実務',
    '9784502364419': 'BASIC&PRACTICE 割増賃金の基本と実務(第2版)',
    '9784502390111': '企業買収の実務プロセス 第3版',
    '9784502397615': '経験者が語るQ&A電子契約導入・運用実務のすべて',
    '9784502406010': '業務委託契約書作成のポイント',
    '9784502411915': 'インターネットにおける誹謗中傷法的対策マニュアル',
    '9784502419614': 'プライバシーポリシー作成のポイント',
    '9784502449512': 'Q&A海事・物流・貿易の契約実務と危機管理',
    '9784535002067': 'コンメンタール行政法I 行政手続法・行政不服審査法（第3版）',
    '9784535002326': '民事判例Ⅳ 2011年後期',
    '9784535002333': '民事判例V 2012年前期',
    '9784535002340': '民事判例VI 2012年後期',
    '9784535002357': '民事判例VII 2013年前期',
    '9784535002364': '民事判例VIII 2013年後期',
    '9784535002388': '民事判例X 2014年後期',
    '9784535002395': '民事判例XI 2015年前期',
    '9784535002401': '民事判例Ⅻ 2015年後期',
    '9784535002418': '民事判例13 2016年前期',
    '9784535002425': '民事判例14 2016年後期',
    '9784535002432': '民事判例15 2017年前期',
    '9784535002449': '民事判例16 2017年後期',
    '9784535002456': '民事判例17 2018年前期',
    '9784535002463': '民事判例18 2018年後期',
    '9784535002470': '民事判例19 2019年前期',
    '9784535002487': '民事判例20 2019年後期',
    '9784535002494': '民事判例21 2020年前期',
    '9784535002500': '民事判例22 2020年後期',
    '9784535002517': '民事判例23 2021年前期',
    '9784535002524': '民事判例24 2021年後期',
    '9784535002531': '民事判例25 2022年前期',
    '9784535002548': '民事判例26 2022年後期',
    '9784535521742': '2016年改正刑事訴訟法・通信傍受法 条文解析',
    '9784535522220': '民事訴訟判例 読み方の基本',
    '9784535522602': '基礎から学ぶ簡易裁判所の諸手続 判事が語る実務のポイント',
    '9784535522671': '新・金融商品取引法ハンドブック(第4版)',
    '9784535523319': 'コンメンタール 借地借家法 〔第 4 版〕',
    '9784535523395': '離婚後の子の監護と面会交流 子どもの心身の健康な発達のために',
    '9784535523548': '民法総則（第2版）',
    '9784535523760': 'シリーズ実務税法解説 取引相場のない株式の税務 ー評価の基礎・売買時価の概要と留意点ー 第４版',
    '9784535523845': '法律文書作成の基本(第2版)',
    '9784535523852': '応用刑法I 総論',
    '9784535523869': '基本行政法判例演習',
    '9784535524194': '基本刑事訴訟法I 手続理解編',
    '9784535524378': '刑事訴訟法の思考プロセス',
    '9784535525061': '最新 意匠審査基準・判例解説 新規性（類似）と創作非容易性',
    '9784535525320': '知る権利と情報公開の憲法政策論 日本の情報公開法制における知る権利の生成・展開と課題',
    '9784535525429': '刑事訴訟法［第10版］',
    '9784535525498': '建設業法と建設業許可 行政書士による実務と解説 第２版',
    '9784535525573': '判例特別刑法［第4集］',
    '9784535525733': '行政法Ⅰ 行政法総論',
    '9784535525825': '先端刑法各論 現代刑法の理論と実務',
    '9784535526198': '要件事実論の総合的展開 その汎用性を説き論証責任論に及ぶ',
    '9784535526211': '物権法 第2版',
    '9784535526259': '民事訴訟法【第2版】',
    '9784535526402': '新しい土地法 人口減少・高齢化社会の土地法を描く',
    '9784535526600': '実践 民事執行法 民事保全法 ［第3版補訂版］',
    '9784535526761': '表現の自由の現代的展開',
    '9784535526907': 'ケースから読み解く法医学 正しい死因究明のために',
    '9784535526921': 'パラリーガルの実務感覚から学ぶ民事訴訟・執行・保全',
    '9784535527263': 'インターネット時代のヘイトスピーチ問題の法的・社会学的捕捉',
    '9784535527409': '紛争類型から学ぶ応用民法I 総則・物権',
    '9784535540118': '行政訴訟による憲法的価値の確保 法治国原理・法と経済学に基づく行政法理論',
    '9784535806917': '刑法Ⅱ 各論',
    '9784535806931': '租税法',
    '9784539726624': '窓口の相談事例にみる 事項別 不動産登記のQ&A210選 8訂版',
    '9784539727805': '雇止め 裁判所の判断がスグわかる本',
    '9784539727928': '目指せ合格！ 社労士Ｖ イラストでわかる労働判例100',
    '9784539727973': '弁護士のためのイチからわかる相続財産管理人・不在者財産管理人の実務',
    '9784539727980': '会社の廃業をめぐる法務と税務',
    '9784539728017': '5訂版 わかりやすい不動産登記の申請手続',
    '9784539728062': '実用 各種証明書のとり方',
    '9784539728222': '遺言信託の法務と文例 遺言で設定する民事信託Q&A',
    '9784539728246': '組織再編税制の失敗事例',
    '9784539728369': '働き方の多様化に備える 労働条件通知書兼労働契約書の書式例と実務 Wordで使えるCD-ROM付',
    '9784539728406': '同一労働同一賃金 裁判所の判断がスグわかる本',
    '9784539729359': '税務申告を見据えた 遺産分割協議書の作成とケース別条項例',
    '9784641017214': '新版注釈民法(２１)親族(１) 総則・婚姻の成立・効果【復刊版】725条～762条',
    '9784641017245': '版注釈民法(２４)親族(４) 親子(２)養子【復刊版】792条～817条の11',
    '9784641017979': '注釈日本国憲法(2) 10条-24条',
    '9784641018457': '逐条解説 刑事収容施設法 第3版',
    '9784641125704': '民事裁判実務の基礎／刑事裁判実務の基礎',
    '9784641135635': '金融と法 企業ファイナンス入門',
    '9784641137387': '中小企業の事業承継',
    '9784641137691': '2016年改正 新しいマンション標準管理規約',
    '9784641138070': 'リーガルマインド商法総則・商行為法〔第3版〕',
    '9784641138186': '家族法〔第4版〕',
    '9784641138247': "不動産取引紛争の実践知〔LAWYERS' KNOWLEDGE〕",
    '9784641138407': "独禁法務の実践知〔LAWYERS' KNOWLEDGE〕",
    '9784641215016': 'ジュリスト増刊 実務に効く M&A・組織再編判例精選',
    '9784641215023': 'ジュリスト増刊 実務に効く コーポレート・ガバナンス判例精選',
    '9784641215047': '実務に効く 知的財産判例精選',
    '9784641215054': 'ジュリスト増刊 実務に効く 公正取引審決判例精選',
    '9784641215061': '実務に効く 事業再生判例精選',
    '9784641215085': 'ジュリスト増刊 実務に効く 担保・債権管理判例精選',
    '9784641215092': 'ジュリスト増刊 実務に効く 企業犯罪とコンプライアンス判例精選',
    '9784641227132': 'よくわかる入管法 第4版',
    '9784641227187': '番号法の逐条解説 第2版',
    '9784641243057': '労働基準法〔第5版〕',
    '9784641711228': '相続と法実務 ［連載誌面合本版］',
    '9784785710002': 'Q&A株式制度の改善・会社運営の電子化 : 平成13年改正商法',
    '9784785714338': 'Q&A 不動産登記法',
    '9784785717599': '金融課税法講義〔補訂版〕',
    '9784785717735': 'ファンドと金融商品取引法',
    '9784785720582': '株主提案権の行使と総会対策',
    '9784785721589': '法務担当者のための民事訴訟対応マニュアル 〔第2版〕',
    '9784785723309': '開示事例から考える「コーポレートガバナンス・コード」対応',
    '9784785723422': '改正マイナンバー法対応のための業務フローとチェックリスト',
    '9784785723477': 'タクティクスアドバンス 民法 2016',
    '9784785723545': '営業秘密Q&A80',
    '9784785723606': 'コンメンタール 会社法施行規則・ 電子公告規則［第2版］',
    '9784785723804': '改正会社法下における実務のポイント',
    '9784785723828': 'アドバンス会社法',
    '9784785724313': '会社法の実務',
    '9784785724634': 'コーポレートガバナンス・コードに対応した招集通知・議案の記載例',
    '9784785725006': 'D&O保険の実務',
    '9784785725396': 'ビルオーナーのための建物賃貸借契約書の法律実務〔第２版〕',
    '9784785725662': 'はじめて学ぶ社外取締役・社外監査役の役割',
    '9784785726409': 'ストーリーに学ぶ・所有者不明土地の論点',
    '9784785726652': '人事労務の法律問題対応の指針と手順',
    '9784785726744': 'ブロックチェーンビジネスとICOのフィジビリティスタディ',
    '9784785726799': '金融商品取引法コンメンタール 1〔第2版〕 定義・開示制度',
    '9784785727697': 'ユーザを成功に導くAI開発契約',
    '9784785727925': '新型コロナ危機下の企業法務部門',
    '9784785727970': '個人情報保護法制大全',
    '9784785728373': '実務解説 薬機法',
    '9784785729127': 'ESGと商事法務',
    '9784785729165': '会社分割ハンドブック〔第3版〕',
    '9784785729400': '破産管財人・管財人代理の実務──ある工務店事案を素材に',
    '9784785729875': 'ダイアローグ争点整理Ⅰ——裁判、特に争点整理における事実認定の約束事を用いて',
    '9784785730031': '金融商品取引業のコンプライアンスQ&A',
    '9784785730048': '消費者法',
    '9784785730345': '新・税務訴訟入門',
    '9784785730413': '定型約款の実務Q&A〔補訂版〕',
    '9784788282933': '雇用形態・就業形態別で示す 就業規則整備のポイントと対応策',
    '9784788284425': '判決例・審判例にみる 婚姻外関係 保護基準の判断―不当解消・財産分与・死亡解消等―',
    '9784788285163': '「子の利益」だけでは解決できない 親権・監護権・面会交流事例集',
    '9784788285835': 'ミス事例でわかる 源泉所得税の実務ポイント',
    '9784788286290': '法律家のための 相続預貯金をめぐる実務',
    '9784788286429':
      '改正民法対応 Ｑ＆Ａ 民事法における 期間・期日・期限－民法・借地借家法・区分所有法・不動産登記法・民事訴訟法・家事事件手続法－',
    '9784788286924': '実務解説 中小企業の株主総会―手続と書式―',
    '9784788288331': '農地の承継・相続相談対応マニュアル',
    '9784788288942': '会社役員 法務・税務の原則と例外―令和3年3月施行 改正会社法対応―',
    '9784788288959': '目的別 相続対策 選択ガイドブック',
    '9784788289031': 'Q&A 養育費・婚姻費用の事後対応－支払確保と事情変更－',
    '9784788289345': '[三訂版] 遺産分割の理論と審理',
    '9784788289383': '税理士が陥りやすい 相続対策の落とし穴－「争族」防止・納税資金・税額軽減・納税申告－',
    '9784788289550': '実例から読みとく 事業承継トラブルの対応策',
    '9784788289574': '裁判例・指針から読み解く ハラスメント該当性の判断',
    '9784788289673': '医療機関の事業承継相談対応マニュアル',
    '9784788289796': '高齢者をめぐる賃貸借実務対応マニュアルー入居・管理・死亡等による契約終了・再募集ー',
    '9784797223989': '新漁業法',
    '9784797270358': '消費者法特別講義 医事法',
    '9784797280296': '倒産法',
    '9784797280579': '災害行政法',
    '9784797281101': '国際人権法と日本の法制',
    '9784818519206': '第2版 経営者のための労働組合法教室',
    '9784818519466': '判例から探る不利益変更の留意点[第２版]－働き方改革時代の労働条件見直しの留意点',
    '9784890170111': '企業労働法実務入門 書式編 : はじめての人事労務担当者からエキスパートへ',
    '9784890170128': '【改訂版】企業労働法実務入門 はじめての人事労務担当者からエキスパートへ',
    '9784897614335': '安全管理者・社労士・弁護士のための労働災害の解決手引',
    '9784897615417': '労働裁判における解雇事件判例集【改訂 第2版】',
    '9784897617015': '経営側弁護士による精選労働判例集 第8集',
    // '9784897618012': '労災保険適用事業細目の解説 令和2年版',
    '9784897618265': '135の判例に学ぶパワーハラスメントの真実',
    '9784897618630': '労働実務事例研究 2021年版',
    '9784897618784': '裁判例で紐解く 企業の安全衛生責任',
    // '9784897618852': '労災保険適用事業細目の解説 令和4年版',
    // '9784897619224': 'わかりやすい年度更新の手続 労働保険の手引 令和5年度版',
    '9784897619248': 'ひと目でわかる 労働保険徴収法の実務 令和5年版',
    '9784897619262': 'ひと目でわかる 労災保険給付の実務 令和5年版',
    '9784897619286': '派遣先に知ってほしい派遣法の実務 その業務委託、派遣（偽装請負）ではありませんか？',
    '9784909712028': '詳説 臨床研究法',
    '9784909712059': '詳説 カルタヘナ法',
    'jpx.017094183': '会社情報適時開示ガイドブック2022 年4 月',
    'jpx.ifguide': '上場インフラファンドに関する情報の適時開示ガイドブック (投資法人・管理会社用) 2021年3月版',
    'jpx.reitguide': '上場不動産投資信託証券に関する情報の適時開示ガイドブック (投資法人・資産運用会社用) 2021年3月版',
    '9784384049251': '事業者必携 最新 労働保険【労災保険・雇用保険】のしくみと届出・申請書類の書き方',
    '9784384049282': '事業者必携 最新 社会保険のしくみと届出書類の書き方',
    '9784384049299': 'すぐに役立つ 入門図解 最新 不動産競売・任意売却の法律と手続き',
    '9784384049312': 'すぐに役立つ 入門図解 最新 株式会社・合同会社・一般社団法人設立の手続きと書式',
    '9784384049329': 'すぐに役立つ 図解とQ&Aでわかる建築基準法・消防法の法律知識',
    '9784384049336': '図解で早わかり 最新 民事訴訟・執行・保全の法律知識',
    '9784803728026': '民事事実認定重要判決50選',
    '9784502270512': '過重労働防止の基本と実務',
    '9784502320712': '図解 不祥事の予防・発見・対応がわかる本',
    '9784502386817': '実務・労働者派遣法概説',
    '9784502406911': 'ＤＸ時代のスポーツビジネス・ロー入門',
    '9784502444418': 'これだけは知っておきたいキャリア支援者の法律ガイドＱ＆Ａ25',
    '9784803707267': '性犯罪捜査全書',
    '9784803742800': '殺傷犯捜査全書',
    'mag_zeikeitsushin_vol-79_no-03': '税経通信　2024年03月号',
    '9784535527546': '冤罪学---冤罪に学ぶ原因と再発防止',
    '9784535806993': 'NBS__債権総論 第2版',
    '9784535807006': 'NBS__家族法 第4版',
    'issn-03873420_202008_vol-92_no-9_01': '法律時報e-Book_202008_感染症対策の正義と法',
    'issn-04393295_no-5': '法学セミナーe-Book_5_教員の多忙化問題---働き方改革のゆくえ',
    '9784766426281': '新標準講義　民法債権総論　全訂3版',
    '9784766426991': '新・考える民法Ⅳ　債権各論',
    '9784766427141': '新債権法の論点と解釈　【第2版】',
    '9784766427592': '物権法改正を読む',
    '9784766427820': '製造物責任法の論点と解釈',
    '9784766428391': '高齢者向け民間住宅の論点と解釈',
    '9784766428919': '新・考える民法Ⅰ 民法総則 第2版',
    '9784766429268': '民法',
    '9784766429275': '働くならこれだけは知っとけ！ 労働法',
    '9784766429398': '保証・人的担保の論点と解釈',
    '9784803729016': '労働事件事実認定重要判決50選',
    '9784803742725': '盗犯捜査全書',
    // '9784897619200': '労働保険事務組合の実務 令和５年版',
    '9784897619712': '労働保険事務組合の実務　令和６年版',
    '9784502432316': '金融商品取引業コンプライアンスハンドブック',
    '9784502357817': '申請事例からみる交通事故後遺障害の等級認定',
    '9784384049350': '事業者必携 改訂新版 記載例つき 民泊ビジネス運営のための住宅宿泊事業法と旅館業法のしくみと手続き',
    '9784384049367': '事業者必携 知っておきたい 建設業事業者のための法律【労務・安全衛生・社会保険】と実務書式',
    '9784417017998': '新・不正競争防止法概説〔第三版〕下巻',
    '9784417018117': '新・商標法概説〔第３版〕',
    '9784417018131': '民事執行法〔改訂版〕',
    '9784417018407': '遺言執行と条項例の法律実務',
    '9784384049381':
      'すぐに役立つ 最新 これだけは知っておきたい！介護保険施設・有料老人ホーム・高齢者向け住宅 選び方と法律問題',
    '9784502355813': '新型コロナウイルス影響下の人事労務対応Ｑ＆Ａ',
    '9784502439414': 'コンテンツ別ウェブサイトの著作権Ｑ＆Ａ〈第２版〉',
    '9784417018049': 'LP13 国家賠償訴訟 (改訂版)',
    '9784417018261': 'LP9 労働関係訴訟Ⅰ (改訂版)',
    '9784897619750': '労働保険徴収法の実務 令和6年版',
    '9784897619774': '労災保険給付の実務 令和6年版',

    // 2024-08-19 Wave 5 https://www.notion.so/legalscape/df23b91407ce4f81bbc57e47e6f3ca05#33d62b9118a640e497d21f8026c4718b
    '4785711337': '一問一答 平成15年改正民事訴訟法',
    '4785711868': '一問一答 新しい破産法',
    '9784326451111': '民法 第10版',
    '9784384046632': '重要事項＆用語 図解 最新 社会保障・介護福祉法律用語辞典',
    '9784384046854': '重要事項＆用語 図解 最新 会社で使う 社会保険・給与計算・労務 法律用語辞典',
    '9784384048124': '事業者必携 働き方改革法に対応！入門図解 会社で使う労働時間・休日・休暇・休職・休業の法律と書式',
    '9784384048254': '事業者必携 最新 入管法・外国人雇用の法律 しくみと手続き',
    '9784384048742': '図解で早わかり 最新 独占禁止法･景表法･下請法',
    '9784384048858': 'すぐに役立つ 知っておきたい！ 最新 身内が亡くなったときの届出と法律手続き',
    '9784384048872': 'すぐに役立つ 図解とQ&Aでわかる 最新 空き家をめぐる法律と税金',
    '9784384048902': '事業者必携 これだけは知っておきたい 最新 不動産売買の法律と実務書式',
    '9784384048957': '図解で早わかり 福祉サービスの法律と手続き',
    '9784384048964': 'すぐに役立つ 入門図解 改訂新版 内容証明郵便･公正証書･支払督促の手続きと書式サンプル51',
    '9784384048971': '図解で早わかり 改訂新版 賃貸借のしくみとルール',
    '9784384048988':
      'すぐに役立つ 図解とQ&Aでわかる 改訂新版 セクハラ･パワハラ･マタハラをめぐる法律とトラブル解決法123',
    '9784384048995': '事業者必携 電子帳簿保存法対応 入門図解 経理の基本と実務マニュアル',
    '9784384049008': '図解 最新 知的財産権のしくみと手続きがわかる辞典',
    '9784384049015': '図解で早わかり 改訂新版 最新 行政法のしくみ',
    '9784384049046':
      'すぐに役立つ 電子商取引から削除請求まで 図解とQ&Aでわかる 最新 ネットトラブルをめぐる法律とトラブル解決法',
    '9784384049060': '図解で早わかり 三訂版 消費者契約法･特定商取引法･割賦販売法のしくみ',
    '9784384049138': '図解で早わかり 最新 裁判･訴訟の基本と手続き',
    '9784384049145': '図解で早わかり 最新 交通事故の法律と手続き',
    '9784384049152': '図解で早わかり 改訂新版 税金のしくみと手続きがわかる事典',
    '9784384049169': '図解で早わかり 最新 障害者福祉の法律と手続きがわかる事典',
    '9784384049176': '事業者必携 入門図解 採用から退職まで 給与計算・賞与・退職手続きの法律と税金実務マニュアル',
    '9784384049183': '図解で早わかり 人事労務・社会保険から経理、契約事務まで 最新 会社の事務と手続きがわかる事典',
    '9784384049190': '事業者必携 入門図解 最新 就業規則の作り方と社内規程サンプル集',
    '9784384049206': '図解で早わかり 最新 土地・建物の法律 基本と手続きがわかる事典',
    '9784384049213': '事業者必携 最新 特定商取引法･景品表示法･個人情報保護法の法律入門',
    '9784384049220': 'すぐに役立つ 入門図解 最新 家事事件手続法のしくみと手続き 実践書式50',
    '9784384049237':
      '事業者必携 入門図解 インボイス制度に対応！最新 ビジネス契約書と請求書・領収書・印鑑・印紙税の実務知識',
    '9784384049244': '図解で早わかり 改訂新版 刑法のしくみ',
    '9784474058828': '心療内科産業医と向き合う職場のメンタルヘルス不調 ～事例で解説 会社と社員が最適解を導く方法',
    '9784474064287': '「ルール」徹底活用型ビジネスモデル入門 SDGs対応を強みに変える',
    '9784474065000': 'パワハラ管理職 指導できない管理職 人事が直面する職場トラブル（ハラスメント個別対応実例集）',
    '9784474066496': '役員・従業員の不祥事対応の実務 社外対応・再発防止編',
    '9784492093009': '図解ひとめでわかるリスクマネジメント（第２版）',
    '9784492093160': '図解 ひとめでわかる内部統制 第３版',
    '9784492093306': '令和元年改正対応 図解 新会社法のしくみ（第4版）',
    '9784492093337': '図解 コンプライアンス経営（第５版）',
    '9784492270585': '【新版】ここからはじまる 早わかり労働安全衛生法',
    '9784492270592': '執行役員制度(第五版) 運用のための理論と実務',
    '9784492681442': '決済インフラ入門〔２０２０年版〕 仮想通貨、ブロックチェーンから新日銀ネット、次なる改革まで',
    '9784502272912': '初めての人のための 英文・和文IT契約書の実務',
    '9784502306716': '外国人雇用の労務管理と社会保険',
    '9784502325915': '中小企業に関わるすべての税理士・弁護士へ ストーリーで学ぶ初めての民事再生',
    '9784502366215': 'ゼロからわかる電子契約の実務',
    '9784535002302': '民事判例II 2010年後期',
    '9784535004054': 'セミナー叢書 法令解釈の常識',
    // '9784535004061': 'セミナー叢書 法令作成の常識',
    '9784535519725': 'ADR仲裁法 第2版',
    '9784535522183': '可視化・盗聴・司法取引を問う',
    '9784535522725': '交通事故事件弁護学入門［第2版］',
    '9784535523722': '憲法論点教室 第2版',
    '9784535524361': '判例民事訴訟法入門',
    '9784535526310': '民法ノート|物権法①【第4版】',
    '9784535526709': '現代教育法',
    '9784535526730': '民法講義録〔第3版〕',
    // '9784535526891': 'ジェンダー平等社会の実現へ―「おかしい」から「あたりまえ」に',
    '9784539746974': '5年改正版 減価償却資産の耐用年数表とその使い方',
    '9784641018389': '旅券法逐条解説',
    '9784641135802': '会社法実務解説',
    '9784641136373': '家事事件手続法 第3版',
    '9784641136557': '重点講義 民事訴訟法 上 〔第2版補訂版〕',
    '9784641136885': '重点講義 民事訴訟法 下 第2版補訂版',
    '9784785705374': '一問一答改正会社法',
    '9784785706470': '一問一答平成5年改正商法',
    '9784785708764': '一問一答民事再生法',
    '9784785709242': '一問一答個人再生手続 : 平成12年民事再生法改正の解説',
    '9784785718411': 'クリエイトする人たちのための基本からの著作権',
    '9784785718770': 'Q&A 被災者生活再建支援法',
    '9784785720094':
      '西村高等法務研究所叢書7 自社株対価 TOB による国際企業買収──クロスボーダー M&A の新たな手法について',
    '9784785721848': '西村高等法務研究所叢書⑨ アクティビスト・敵対的買収対応の最新動向 ──各種事例を通じた分析と検討',
    '9784785723736': 'コーポレートガバナンス・コードが求める取締役会のあり方',
    '9784785726942': '株主総会判例インデックス',
    '9784785727369': 'アドバンス金融商品取引法〔第3版〕',
    '9784785728090': '希望の法務 法的三段論法を超えて',
    '9784785728236': '一問一答 令和2年改正個人情報保護法',
    '9784785728250': 'AIの法律',
    '9784785729257': 'デジタルトランスフォーメーションハンドブック',
    '9784788282896': 'Q&A改正個人情報保護法と企業対応のポイント',
    '9784788282919': '〔三訂版〕根抵当権の法律と登記',
    '9784788283763': '養育費・扶養料・婚姻費用 実務処理マニュアル',
    '9784788285781': '不動産取引における 心理的瑕疵・環境瑕疵 対応のポイント',
    '9784788286184': '相続・贈与と生命保険をめぐるトラブル予防・対応の手引',
    '9784788288324': '最新 同一労働同一賃金 27の実務ポイント－令和３年４月完全施行対応－',
    '9784797223927': '新海商法（増補版）',
    '9784797281033': '婦人保護事業から女性支援法へ ― 困難に直面する女性を支える',
    '9784797281064': 'スポーツを法的に考えるⅠ ― 日本のスポーツと法・ガバナンス',
    '9784797281132': 'マンションの管理組合とは何か',
    '9784818520141': '「問題社員」対応の法律実務 トラブル防止の労働法',
    '9784897615998': '最新 企業実務に即したモデル社内規程と運用ポイント',
    '9784897617718': '労働実務事例研究 2019年版',
    '9784897617756': '改訂第2版 楽に読める安衛法 概要と解説',
    '9784905278436': '外為法ハンドブック2023 犯収法その他関連法令も含めた外為取引への実務的アプローチ',
    '9784384049343': '事業者必携 入門図解 2024年問題に対応! 労働時間・休日・休暇・休業の法律と手続き',
    '9784502360015': '行政事件における要件事実と訴訟実務',
    '9784502384110': '同一労働同一賃金の基本と実務',
    '9784502411816': '新・会社法実務問題シリーズ／９組織再編',
    '9784535002043': 'コンメンタールマンション区分所有法',
    '9784535527416': '紛争類型から学ぶ応用民法II---債権総論・契約',
    '9784766427042': '刑事法実務の基礎知識特別刑法入門１　【第2版】',
    '9784766429121': '大学生が知っておきたい 消費生活と法律 【第2版】',
    '9784797268126': '〈実践〉生命保険の要件事実',
    '9784897618616': '中小企業向け　社内調査の進め方',
    '9784897619699': '労災保険適用事業細目の解説　令和６年版',
    '9784502481314': '自動運転・ＭａａＳビジネスの法務',
    '9784502476310': 'チェックリストでリスクが見える内部統制構築ガイド',
    '9784502441011': 'Ｑ＆Ａでわかる業種別法務物流・倉庫・デリバリー',
    '9784502418518': 'Ｑ＆Ａでわかる業種別法務キャッシュレス決済',
    '9784502414817': '外為法に基づく投資管理',
    '9784502271014': '販売店契約の実務',
    '9784000616072': '憲法　第八版',
    '9784004319733': '敵対的買収とアクティビスト',
    '9784474077591': 'クラウドサイン導入・活用ハンドブック―電子契約の社内定着実践プロセス―',
    '9784797236583': '労働法〔第8版〕',
    '9784803744132': 'Q＆A　実例 交通事件捜査における現場の疑問〔第２版〕',
    '9784803743456': '条文あてはめ刑法',
    '9784803743432': '過失犯　犯罪事実記載要領〔第2版〕',
    '9784803743364': '刑法〔第4版〕',
    '9784803743333': '実践志向の捜査実務講座_特別刑事法犯の理論と捜査［2］',
    '9784803743159': '実践志向の捜査実務講座_特別刑事法犯の理論と捜査［1］',
    '9784803742923': 'マネー・ローンダリング罪　捜査のすべて〔第3版〕',
    '9784803742893': '警察官のための死体の取扱い実務ハンドブック',
    '9784803742602': '捜査・公判のための実務用語・略語・隠語辞典',
    // '9784803742565': '犯罪収益規制と財産回復',
    '9784803724950': '規範あてはめ刑事訴訟法',
    '9784803724912': '取調べハンドブック',
    '9784803724790': '刑事訴訟法〔第5版〕',
    '9784417017981': '新・不正競争防止法概説〔第三版〕上巻',
    '9784417018308': '終活と相続・財産管理の法律相談',
    '9784417018346': '逐条解説 特定商取引法Ⅰ',
    '9784417018353': '逐条解説 特定商取引法Ⅱ',
    '9784417018438': '未払い残業代請求の法律相談',
    '9784417018322': '示談・調停・和解の手続と条項作成の実務',
    '9784384049374': '改訂新版 すぐに役立つ これならわかる 障害者総合支援法と支援サービスのしくみと手続き',
    '9784502314513': 'スモールＭ＆Ａの教科書',
    '9784502321511': '事例でわかる不正・不祥事防止のための内部監査',
    // '9784502334412': '取締役会評価のすべて',
    // '9784502339714': 'アクティビストの衝撃',
    '9784417018278': 'LP9 労働関係訴訟Ⅱ (改訂版)',
    '9784897619736': '労働保険の手引 令和6年度版',
    '9784909090966': 'Q&A同族会社のオーナー社長をめぐる借入金・貸付金消去の税務',
    '9784909090584': 'Q&A所得税法・消費税法における みなし譲渡のすべて',
    '9784909090355':
      'Q&A「税理士〈FP〉」「弁護士」「企業CFO」単独で完結できる 中小企業・零細企業のためのM&A実践活用スキーム',
    '9784909090812': '判例に学ぶ 税法条文の〝実践的〟読み方',
    '9784909090836': 'Q&A新版みなし贈与のすべて',
    '9784909090577': '新版 役員1年目の教科書',
    '9784909090898': '［新版］専門家のための事業承継入門ー事例で学ぶ！事業承継フレームワーク',
    '9784911064016': '条文・事例・図表で読み解く　繰越欠損金の税務',
    '9784909090560': '詳解 有利発行課税',
    '9784909090690': '子会社株式簿価減額特例ー国際的な配当をめぐる税務',
    '9784909090850': 'まだ間に合う！最新 事業承継税制',
    '9784909090348': 'Q&A・零細企業のための 事業承継戦略と実践的活用スキーム',
    '9784909090553': 'Q&A外国子会社合算税制のすべて',
    '9784909090447': 'Q&Aみなし配当のすべて',
    '9784909090508': '「処分取消事例」にみる　重加算税の法令解釈と事実認定',
    '9784785729042': 'コーポレートガバナンス・コードの実務〔第4版〕',
    '9784785729677': '法令で学ぶ・用語・重要概念 経営とルール活用戦略 ESG/SDGs',
    '9784785729622': '内部通報制度の理論と実務〔第2版〕',
    '9784785729516': '知的財産契約の実務 理論と書式 意匠・商標・著作編',
    '9784785729509': '知的財産契約の実務 理論と書式 先端技術・情報編',
    '9784785729493': '知的財産契約の実務 理論と書式 特許編',
    '9784785729073': '社債法〔第2版〕',
    '9784785729066': '役員のための法律知識〔第3版〕',
    '9784785729486': '2020年個人情報保護法改正と実務対応〔改訂版〕',
    '9784785729448': 'コンメンタール会社計算規則・商法施行規則〔第４版〕',
    '9784785721862': 'よくわかる国際仲裁',
    'jpx.017094198': '東京証券取引所会社情報適時開示ガイドブック（２０２４年４月版）',
    '9784417018094': '民事執行の法律相談',
    '9784417017905': '実務解説 借地借家法 (第3版)',
    '9784502465314': '会社法務書式集',
    '9784502447310': '不正調査の「法律」「会計」「デジタル・フォレンジック」の実務',
    '9784502435713': 'ＸＲ・メタバースの知財法務',
    '9784502410017': '体系経済刑法',
    '9784502399510': '労働法講義',
    '9784502389511': '商業登記実務から見た合同会社の運営と理論',
    '9784502368516': '最新シンジケート・ローン契約書作成マニュアル',
    '9784766429510': '犯罪被害者への賠償をどう実現するか――刑事司法と損害回復',
    '9784766429695': '新・考える民法Ⅲ 債権総論 第2版',
    '9784589040930': '改正債権法コンメンタール',
    '9784589041883': '紛争解決のためのシステム開発法務―AI・アジャイル・パッケージ開発等のトラブル対応',
    '9784539729250': 'クラウド会計を活用した 電子帳簿保存法対応の実務',
    '9784539729984': '改訂版 デジタル資産と電子取引の税務',
    '9784539747018': '令和 6 年度 よくわかる 税制改正と実務の徹底対策',
    '9784803724660': 'Q&A 実例 取調べの実際',
    '9784803724851': '新時代における刑事実務',
    '9784803742787': '不当要求等対処ハンドブック',
    '9784803744279': '交通事故実況見分調書作成実務必携 〜交通事故実況見分のポイント〜',
    '9784384049398': 'すぐに役立つ Q&Aでわかる 最新 近隣問題【建築・道路・境界・住環境】の法律とトラブル解決法',
    '9784384049404': 'すぐに役立つ これだけは知っておきたい！遺言書の書き方と生前贈与の法律と税金',
    '9784589039422': '新ハイブリッド民法4 債権各論',
    '9784589039637': '新ハイブリッド民法3 債権総論',
    '9784589040633': '新プリメール民法3 債権総論',
    '9784589040640': '新プリメール民法4 債権各論',
    '9784589042316': '新プリメール民法1 民法入門・総則',
    '9784589042323': '新プリメール民法2 物権・担保物権法',
    '9784589042651': '新プリメール民法5 家族法',
    '9784589042828': '新ハイブリッド民法2 物権・担保物権法',
    '9784589042934': '新ハイブリッド民法1 民法総則',
    '9784589043351': '新ハイブリッド民法5 家族法',
    '9784417017943': 'ITインターネットの法律相談(改訂版)',
    '9784417016274': 'LP12 民事再生',
    '9784641243149': '条文から学ぶ 独占禁止法〔第2版〕',
    '9784641243323': '独禁法講義〔第9版〕',
    '9784417018223': 'コンメンタール家事事件手続法Ⅰ',
    '9784417018230': 'コンメンタール家事事件手続法Ⅱ',
    '9784417018391': '大コンメンタール刑事訴訟法(第3版) 第6巻',
    '9784417018605': '離婚調停・離婚訴訟 四訂版',
    '9784417016847': '独占禁止法の法律相談',
    '9784417018285': '下請法の法律相談',
    '9784417018339': '相続・遺言・遺産分割',
    '9784788292055': '一般 公益 社団・財団法人 議事録モデル文例集',
    '9784788291935': '法律家・宅建業者のための 任意売却相談対応マニュアル',
    '9784788291256': 'Q&A 民事保全・執行 実務の勘どころ110 ―申立てから事件終了まで―',
    '9784788291065': 'Q&A 民法と不動産登記 ―実体法から登記手続への架け橋―',
    '9784788290617': '任意後見の実務 ―フローチャートとポイント―',
    '9784788290549': '令和3年民法・不動産登記法改正対応 所有者不明土地と 空き家・空き地をめぐる法律相談',
    '9784788289451': '若手弁護士・パラリーガル必携 委任状書式百選',
    '9784788287709': '相続人不存在・不在者 財産管理の手続と書式',
    // '9784502312212': '社内規程立案の手引き',
    '9784502359811': 'Q&A「職場のハラスメント」アウト・セーフと防止策',
    '9784502433818': 'Q&A電子契約入門',
    '9784502449413': '詳解 合同会社の法務と税務',
    '9784502454912': '経済安全保障×投資規制・貿易管理 外為法 Q&A',
    '9784502472817': 'サイバーセキュリティ対応の企業実務 平時・有事における組織的・法的対策の進め方',

    // 2024-11-12 Wave 6 https://www.notion.so/legalscape/W-H-10a33b49f4604b7eb88b7bb9846071d0#1319c1e22e4980e89cc2f3a9f6c4a13b
    '4785711345': '改正 担保・執行法の解説',
    '9784326403271': '金融から学ぶ会社法入門',
    '9784384046564': '図解で早わかり 最新 商業登記のしくみ',
    '9784384048384': '図解で早わかり 改正法対応！最新 会社法のしくみと手続き',
    '9784384048643': '図解で早わかり 最新 商業登記の基本と実務',
    '9784384048759': '事業者必携 事業再編･M&A[合併･会社分割･事業譲渡]の法律と手続き',
    '9784474023406': '非公開会社の法務 ─準組合法理のやさしい解説─',
    '9784474102996': '企業承継法の理論Ⅱ(中小企業法研究第二巻)-判例・立法-',
    '9784502353314': '新型コロナウイルス影響下の法務対応',
    '9784535004047': 'セミナー叢書 法令用語の常識',
    '9784535522466': '新法令解釈・作成の常識',
    '9784535523432': '企業法入門〔第5版〕',
    '9784535523838': '基本刑法I 総論 第3版',
    '9784535524736': '環境訴訟法［第2版］',
    '9784535525016': '行政書士のための要件事実の基礎 第2版',
    '9784535526952': '岐路に立つ日本の社会保障 ポスト・コロナに向けての法と政策',
    '9784535806801': '契約法',
    '9784535806863': '会社法',
    '9784535806887': '憲法Ⅰ 総論・統治［第2版］',
    '9784535806894': '憲法Ⅱ 人権[第2版]',
    '9784535806955': '事務管理・不当利得・不法行為',
    '9784535806979': '刑事訴訟法',
    // '9784785720575': '新・株主総会徹底対策 平成25年総会の重要トピック',
    '9784785723958': '実務解説職務発明 : 平成27年特許法改正対応',
    '9784785724337': 'コーポレート・ガバナンスの現状分析 : 有価証券報告書・臨時報告書を対象に',
    '9784785724535': '実務解説 会社法',
    '9784785725457': 'M&A担当者のための独禁法 ガン・ジャンピングの実務',
    '9784785725594': '公取委実務から考える 独占禁止法',
    '9784785725655': '取引ステップで考える実践的M&A入門',
    '9784785725761': '打消し表示の実態と景品表示法の考え方――調査報告書と要点解説',
    '9784785726010': '一問一答 民法（債権関係）改正',
    '9784785726225': '必携債権法を実務から理解する21講',
    '9784785726263': '債権法改正対応 不動産賃貸借契約の実務 Q&A',
    '9784785726751': '新しい取締役会議事録作成の実務',
    '9784785727314': '統合報告で伝わる価値創造ストーリー',
    '9784785728144': '医薬・ヘルスケアの法務 規制・知財・コーポレートのナビゲーション 〔第2版〕',
    '9784785728366': '企業結合ガイドライン〔第2版〕',
    '9784785728441': '会社法を読み解く 実務と裁判例を踏まえて',
    '9784785728700': 'ファンド契約の実務Q&A 第3版',
    '9784785752538': '別冊商事法務 No.419 招集通知・議案の記載事例 平成29年版',
    '9784785753047': '別冊商事法務No.470 新しい買収防衛策の考え方',
    '9784788288041': 'Ｑ＆Ａと事例にみる 不動産登記請求訴訟の実務',
    '9784788288058': 'Ｑ＆Ａ 実務家のための暗号資産入門－法務・会計・税務－',
    '9784788288072': 'ケース別 土地評価 減価要因の着眼点－形状及び権利関係等－',
    '9784788288294': 'Q&A 高齢者の財産管理をめぐる実務－契約の選択・締結・履行・終了－',
    '9784788289048': '消費税 適用判断の原則と例外',
    '9784788289116': '介護サービス事業における 困りごと相談ハンドブック－ソーシャルワーカーの実務対応－',
    '9784788289123': '新しい働き方に伴う 非正規社員の処遇－適法性判断と見直しのチェックポイント－',
    '9784788289185': 'ケースでわかる 成功する募集・採用の最新ノウハウ－適正な対応と法律実務－',
    '9784788289192': 'タクシー事業のための 労務管理一問一答',
    '9784788289208': '教育・保育機関におけるハラスメント・いじめ対策の手引 大学・小中高・幼保の現場対応',
    '9784788289222': 'ヒアリングシートを活用した 遺産分割相談 聴取事項のチェックポイント',
    '9784788289338': '最新 ハラスメント対策 モデル文例集－厚労省導入マニュアル対応－',
    '9784788289772': 'ガイドライン 多様な生活環境にある子どもへの対応－障害・不登校・生活困窮・児童虐待・外国人など－',
    '9784788289802': '新生活様式対応 就業規則等整備・運用のポイント',
    '9784788289857': '窓・開口部をめぐるトラブル予防・対応の実務―設計・施工で押さえておきたいポイント―',
    '9784788290341': 'サブスクビジネスをめぐる法律実務 －サブスクリプション・フリーミアム・シェアリングエコノミー等－',
    '9784788290358': 'ケース別 権利に関する嘱託登記ー実務のポイントと書式ー',
    '9784788290396': '遺言書・贈与契約書チェックのポイントー｢やってしまいがちな記載｣とその改善例ー',
    '9784788290457': '簡易裁判所における 交通事故訴訟と和解の実務',
    '9784788290464': '顧問先等の経営危機 対応マニュアル－現状確認・資金確保・経費見直し・再建と清算－',
    '9784788290525': '民法総則の基礎がため',
    '9784788290655': '過大要求・悪質クレームへの企業対応の実務 －取引先・消費者・株主の問題行動－',
    '9784788290747': '自然災害・感染症をめぐる賃貸トラブル解決の手引－被災入居者・テナントへの対応－',
    '9784788290785': '育児・介護を行う社員をめぐる 職場の労務管理アドバイス－2022年10月施行・改正育介法対応－',
    '9784788290792': '次世代ビジネス対応 契約審査手続マニュアル －「新しい資本主義」を踏まえた契約類型－',
    '9784788290839': '令和3年改正民法・不動産登記法対応 ケース別 共有に関する不動産登記',
    '9784788290914': '税理士が知っておきたい 相続発生後でもできる相続税対策',
    '9784788291140': '不動産取引のための 水害リスクをめぐる法律問題－Q&Aと紛争事例解説－',
    '9784797211634': '担保物権法 民法大系 3',
    '9784818519473': '取締役の教科書 第2版 これだけは知っておきたい法律知識',
    '9784897616889': '改訂第2版 求職者支援制度の解説',
    '9784502276118': '中小企業買収の法務',
    '9784766429190': '入門講義会社法【第3版】',
    '9784797229165': '新会社法〔第6版〕',
    '9784909090331': 'Q&A中小企業のための資本戦略と実践的活用スキーム',
    '9784785729035': 'IT ビジネスの契約実務〔第2版〕',
    '9784785728786': 'コンメンタール会社法施行規則・電子公告規則〔第3版〕',
    '9784785730536': '社外監査役の手引き〔第2版〕',
    '9784785730529': 'ビジネスのためのメタバース入門 ―メタバース・リアル・オンラインの選択と法実務',
    '9784785724467': '金融商品取引法コンメンタール1 ―定義・開示制度',
    '9784417018179': '類型別 労働関係訴訟の実務〔改訂版〕II',
    '9784417018162': '類型別 労働関係訴訟の実務〔改訂版〕I',
    '9784417017820': '医療事故の法律相談 最新青林法律相談29',
    '9784417016885': '建築紛争 判例ハンドブック',
    '9784384049428': '改訂新版 すぐに役立つ 入門図解 最新 介護保険【サービス・費用】と介護施設のしくみと手続き',
    '9784384049411': 'すぐに役立つ  知っておきたい  最新 不動産の売買・保有・賃貸・相続のための税金の基本',
    '9784785730543': 'ガイダンス 監査役・監査役会の実務〔第2版〕',
    '9784785730468': '女性・独立社外取締役──就任経緯、取締役会準備、兼職の実情から「悩み」の克服法まで',
    '9784785730253': 'Q&A で学ぶメタバース・XRビジネスのリスクと対応策',
    '9784785730215': '詳説・カーブアウトM&A',
    '9784785730161': '発信者情報開示命令の実務',
    '9784785729998': '設例で学ぶ オーナー系企業の事業承継・M&A における法務と税務〔第 2 版〕',
    '9784785729325': '公益通報者保護法に基づく事業者等の義務への実務対応',
    '9784785727796': '一問一答 令和元年民法等改正 ──特別養子制度の見直し',
    '9784820726760': '若手弁護士のための 民事弁護 初動対応の実務',
    '9784820759836': '現役法務と顧問弁護士が実践している ビジネス契約書の読み方・書き方・直し方',
    '9784820759744': '現役法務と顧問弁護士が書いた 契約実務ハンドブック',
    '9784820728252': 'コンプライアンス実務ハンドブック',
    '9784820727637': '企業法務のための初動対応の実務',
    '9784535002050': 'コンメンタール民事訴訟法VI',
    '9784535002074': 'コンメンタール民事訴訟法VII',
    '9784535002081': 'コンメンタール民事訴訟法III〔第2版〕',
    '9784535002098': 'コンメンタール民事訴訟法IV〔第2版〕',
    '9784535002562': '民事判例28――2023年後期',
    '9784535003507': 'コンメンタール民事訴訟法I〔第3版〕',
    '9784535003514': 'コンメンタール民事訴訟法II〔第3版〕',
    '9784535003538': 'コンメンタール民事訴訟法V〔第2版〕',
    '9784535515819': 'ケースからはじめよう  法と経済学  法の隠れた機能を知る',
    '9784535526280': '少年事件マニュアルー少年に寄り添うために',
    '9784535526693':
      '日本大学法学部叢書 第49巻 競争を否定する選挙法 戦後日本における選挙運動規制の形成過程に関する研究',
    '9784535526938': '講座・現代社会保障法学の論点〔上巻〕 ──基本的論点',
    '9784535526945': '講座・現代社会保障法学の論点〔下巻〕 ──現代的論点',
    '9784535527058': '自治問題研究叢書 地方自治をめぐる規範的秩序の生成と発展',
    '9784535527539': '憲法訴訟の実務と学説',
    '9784535527591': '基礎刑事訴訟法 第2版',
    '9784535527621': '開かれた入管・難民法をめざしてー入管法「改正」の問題点',
    '9784535527638': '新・地方自治法基本解説',
    '9784535527744': '伝聞法則に強くなる 第2版',
    '9784535527881': '情報法制の論点 ──公文書管理・情報公開・個人情報保護',
    '9784535527904': '事務管理・不当利得・不法行為 第4版［セカンドステージ債権法III］',
    '9784535527911': '契約法 第4版［セカンドステージ債権法I］',
    '9784535527928': '債権総論 第4版［セカンドステージ債権法II］',
    '9784535527942': '倒産法と要件事実［法科大学院要件事実教育研究所報第22号］',
    '9784535806610': '日本評論社ベーシック・シリーズ=NBS 刑法I 総論 第2版',
    '9784535806627': '日本評論社ベーシック・シリーズ=NBS 刑法II 各論 第2版',
    '9874535526778': '学校法人ガバナンスの現状と課題 ──令和5年私立学校法改正の理解と実践のために',
    '9784909600370': '著作権の税務',
    '9784909600351': 'グループ法人税制 【第三版】',
    '9784909600271': '株式交換・株式移転等 実務必携【第二版】',
    '9784909600226': '会社分割実務必携【第二版】',
    '9784909600110': '会社合併実務必携 【第四版】',
    '9784909600066': '医療法人の法務と税務【第四版】',
    '9784502482014': '実務解説 サイバーセキュリティ法',
    '9784502457418': '基礎からわかる「ビジネスと人権」の法務',
    '9784502454417': '企業法務のための ネット・SNS トラブルのルール作り・再発防止',
    '9784502447419': '裁判例からわかる 介護事業の実務',
    '9784502443619': '管理者・施設長に教えたい 介護事業所の“現場法務”',
    '9784502418419': '基本テキスト 企業法総論・商法総則',
    '9784502383410': '国際ビジネス法概論',
    '9784502360817': '社会保険審査会裁決例から考える「障害年金」  各種受給要件の判断ポイント',
    '9784502360213': '商事法講義2(商法総則・商行為)',
    '9784502347719': '基礎からわかる 広告・マーケティングの法律',
    '9784417017936': '法人破産申立て実践マニュアル〔第2版〕',
    '9784417017028': '事例解説 成年後見の実務',
    '9784417016991': '最新裁判実務大系 第4巻 不動産関係訴訟',
    '9784417018643': '逐条解説 割賦販売法 第2版 第2巻',
    '9784417018636': '逐条解説 割賦販売法 第2版 第1巻',
    '9784417018575': '民事訴訟',
    '9784417016557': '遺産分割のための相続分算定方法',
    '9784417015918': 'LP12 商事関係訴訟 (改訂版)',
    '9784384049442': 'すぐに役立つ 入門図解 特許・商標の法律と出願・申請手続き',
    '9784785730598': '不動産登記法〔第3版〕',
    '9784785727642': '商事法論集Ⅲ　金融法論集（下） 信託・保険・証券',
    '9784785726317': 'Q&Aで学ぶGDPRのリスクと対応策',
    '9784785721534': 'やさしい番号法入門',
    '9784417018247': '不動産訴訟の実務から見た改正民法',
    '9784417018209': '倒産法',
    '9784417018018': '婚姻費用・養育費・財産分与の法律相談',
    '9784417017554': '簡裁交通損害賠償訴訟',
    '9784417017318': '貸金業と過払金の半世紀',
    '9784417017073': '争点整理と要件事実',
    '9784000248976': '民事訴訟法　第４版',
    '9784502478215': 'ゼロからわかる電子契約の実務',
    '9784502299612': '企業評価論入門',
    '9784818519589': '改訂新版 テレワーク導入・整備の法的アプローチ－トラブル回避の留意点と労務管理のポイント',
    '9784818519572': '労働災害対応Q&A－企業と役員の責任',
    '9784417018629': '宗教法人の法律相談',
    '9784417018582': '判例からひも解く実務民事訴訟法',
    '9784417018513': '近隣紛争の法律相談',
    '9784417018445': '判例からひも解く実務民法(改訂版)',
    '9784417018025': '電子商取引・電子決済の法律相談',
    '9784417017769': '子の親権・監護・面会交流の法律相談',
    '9784417017585': '介護事故の法律相談',
    '9784417016977': '新・注解 商標法 (下巻)',
    '9784417016960': '新・注解 商標法 (上巻)',
    '9784417016540': '新しい商標と商標権侵害',
    '9784785730765': 'わかりやすい　電子提供制度と株主総会の実務',
    '9784785729882': '公取委実務から考える　独占禁止法〔第２版〕',
    '9784785729707': 'ヘルステックの法務Q&A〔第２版〕',
    '9784797282535': 'ノン・ルフルマン原則と外国人の退去強制',
    '9784797282337': '倒産手続と情報資産',
    '9784417018377': '退職勧奨・希望退職募集・PIPの話法と書式',
    '9784788292499': '税理士が知っておきたい　遺言書でできる相続対策',
    '9784788292352': '遺言無効紛争事件実務マニュアル',
    '9784788292345': '弁護士懲戒の状況と分析－守秘義務と利益相反－',
    '9784788292253':
      '公務員のための　職務をめぐる不当要求等　対応アドバイス－カスハラ・利害者との関係・職員の問題行動－',
    '9784788292222': '弁護士が見落としがちな　相続事案の税務と登記－他士業の視点にみるポイント－',
    '9784788292208': '判例にみる　遺言解釈のポイント－趣旨が不明確、多義的、不記載・誤記、実態との相違、抵触など－',
    '9784788292048': '遺言・遺産分割による財産移転と課税関係のチェックポイント',
    '9784788291386': '論点別インデックスで引く　養育費・婚姻費用判断の考慮要素',
    '9784788291379': '自動二輪車交通事故訴訟の実務',
    '9784788290952': '判例にみる　自転車事故の責任と過失割合－危険運転事例を中心に－',
    '9784877987756': 'こんなときどうする刑事弁護の知恵袋',
    '9784877986063': '責任能力弁護の手引き',
    '9784877984892': '公判前整理手続を活かす〔第２版〕',
    '9784905278429': 'よくわかる事業承継　四訂版',
    '9784905278382': '五訂版　相続・贈与の法律、税金、手続きのポイントＱ＆Ａ',
    '9784909712097': '詳説 薬機法 第６版',
    '9784502502217': 'Ｍ＆Ａコンサルタントに必要な法務スキル',
    '9784502490712': 'プライバシーテックのすべて',
    '9784502474514': '違反・トラブルを未然に防ぐインターネット広告法務ハンドブック',
    '9784502474118': 'スポーツスポンサーシップの基礎知識と契約実務',
    '9784502458019': '「株主との対話」ガイドブック',
    '9784502441615': '類型別　不正・不祥事への初動対応',
    '9784502365317': 'Ｑ＆Ａ引用・転載の実務と著作権法',
    '9784502326813': '新株予約権等・種類株式の発行戦略と評価',
    '9784502312212': '社内規程立案の手引き',
    '9784502269110': '訴訟弁護士入門',
    '9784384049480': 'すぐに役立つ 入門図解 これだけは知っておきたい！建築基準法のしくみ',
    '9784384049473': '事業者必携 入門図解 法人税・消費税のしくみと申告書の書き方',
    '9784766424850': '仮釈放の理論',
    '9784766424744': '債権法改正を読む',
    '9784766422184': '倒産法実務の理論研究',
    '9784905278276': '改訂版　従業員持株会導入の手引',
    '9784803743524': '注釈 銃砲刀剣類所持等取締法〔第３版〕',
    '9784803742947': '業務上過失事件捜査実務必携',
    '9784803712940': '「逃げ得」を許さない交通事件捜査〔第２版〕',
    '9784803707229': '風俗営業法判例集〔改訂版〕',

    // 2024-11-12 PUB-336
    '9784502305719': 'プライベート・エクイティ・ファンドの法務 第2版',
    '9784785730406': '金融商品取引法〔第7版〕',
    '9784641243453': '標準特許法〔第7版〕',
    '9784641138476': '金融商品取引法〔第2版〕',
    '9784502430213': '会社議事録の作り方(第3版)',
  })
    .map(([naturalID, title]) => ({ naturalID, title, key: title.replace(/[\p{P}\p{S}]/gu, '').normalize('NFC') }))
    .sort((a, b) => b.key.localeCompare(a.key, 'ja'))
    .map(({ key, ...rest }) => rest),
);

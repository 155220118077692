import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.iterator.js";
export default defineComponent({
  name: 'LabsWandhInputFormTitleFilterItemGroup',
  props: {
    books: {
      type: Array,
      required: true
    },
    group: {
      type: String,
      required: true
    },
    /** 設定すると上位 limit 件のみを表示する */
    limit: {
      type: Number,
      default: null
    }
  },
  setup: function setup(props) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- input-form-title-filterからしか呼んでいない
    var selection = inject('selection');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- input-form-title-filterからしか呼んでいない
    var filterTextInput = inject('filterTextInput');
    var shownBooks = computed(function () {
      var _props$limit;
      return new Set((filterTextInput.value ? props.books.filter(function (_ref) {
        var title = _ref.title;
        return title.includes(filterTextInput.value);
      }) : props.books).map(function (_ref2) {
        var naturalID = _ref2.naturalID;
        return naturalID;
      }).slice(0, (_props$limit = props.limit) !== null && _props$limit !== void 0 ? _props$limit : undefined));
    });
    return {
      selection: selection,
      filterTextInput: filterTextInput,
      shownBooks: shownBooks
    };
  }
});
import type { components } from 'wklr-backend-sdk/wandh';

const PublicationYearRange = {
  min: 1991, // TODO: 判例の場合もっと古いものもあるがどうしようか未検討
  max: new Date().getFullYear(),
};
const QuestionBytesLimit: components['schemas']['Constraints']['questionBytesLimit'] = 1499;

type WandhState = 'idle' | 'watson-thinking' | 'holmes-thinking' | 'buffering';

export { PublicationYearRange, QuestionBytesLimit, WandhState };
